.colorInputLabel, .inputLabel {
  font-weight: bold;
  font-size: 0.8125rem;
  line-height: 1.1875rem;
  margin: 0;
  padding: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
}
.colorInputRow {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
