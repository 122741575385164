.chargingStationConfigurationActions {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  &>*+* {
    margin-left: 0.5rem;
  }
}

.sessionPaymentTypesConfigured {
  display: flex;
  flex-direction: row;
  align-items: center;

  &>*+* {
    margin-left: 0.5rem;
  }
}

.paymentTypeHeader {
  font-size: 1rem;
  font-weight: bold;
}