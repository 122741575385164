@import '../variables.scss';

.row-actions {
  display: flex;
  justify-content: center;

  button:first-of-type {
    margin-right: 30px;
  }
}

.leftAligned {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}