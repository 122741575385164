@import '../variables.scss';

.dash-form-feedback {
  font-size: 16px;

  &.lg {
    font-size: 18px;
  }

  &.sm {
    font-size: 14px;
  }
}
