.radioGroup {
  label {
    margin-bottom: 0;
  }

  :global(.form-group) {
    margin-bottom: 0;
  }

  :global(.form-check-label) input:before {
    top: calc(50% - 0.5rem);
    position: relative;
  }
}
