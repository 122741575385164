@import '../variables.scss';

.label {
  font-size: var(--bs-paragraph-01);
  color: var(--bs-neutral-700);
  font-weight: 700;
  margin: 0;
  padding: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;

  [type='checkbox'],
  [type='radio'] {
    line-height: var(--brand-label-size-md);
    font-size: var(--brand-checkbox-size-md);
    margin-right: var(--brand-label-size-md);
    position: relative;
    align-self: flex-start;
    width: 20px;
    margin-right: 5px;
  }

  &.lg {
    font-size: var(--bs-paragraph-01);
    margin: 12px 0;
    padding: 0;

    [type='checkbox'],
    [type='radio'] {
      line-height: var(--brand-label-size-lg);
      font-size: var(--brand-checkbox-size-lg);
      margin-right: var(--brand-label-size-lg);
    }
  }

  &.sm {
    font-size: var(--brand-label-size-sm);
    margin: 8px 0;
    padding: 0;

    [type='checkbox'],
    [type='radio'] {
      line-height: var(--brand-label-size-sm);
      font-size: var(--brand-checkbox-size-sm);
      margin-right: var(--brand-label-size-sm);
    }
  }

  &.check {
    padding-left: 21px;
  }

  em {
    color: var(--brand-color-atlantis);
  }
}
