.value {
  color: white;
  font-size: 32px;
}

.title {
  color: white;
}

.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.header {
  padding-left: 0.5em;
}

.graph {
  flex-grow: 1;
}
