@import '../variables.scss';

.input {
  // font-size: var(--brand-component-size-sm);
  font-size: 0.9375rem !important;
  height: inherit;
  margin-bottom: 0.3rem;
  border-radius: unset !important;
  border-top: 0 none !important;
  border-left: 0 none !important;
  border-right: 0 none !important;
  border-bottom: 1px solid var(--bs-neutral-300) !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  margin-bottom: 0 !important;
  line-height: 136%;

  // &.lg {
  //   font-size: var(--brand-component-size-lg);
  // }

  // &.sm {
  //   font-size: var(--brand-component-size-sm);
  // }

  &.valid {
    border-color: var(--bs-smappee-500);

    &:focus {
      border-top: 0 none;
      border-left: 0 none;
      border-right: 0 none;
      border-bottom: var(--bs-smappee-500) solid 1px;
    }
  }

  &.invalid {  
    color: var(--bs-input-text-error);   

    &:focus {
      border-top: 0 none;
      border-left: 0 none;
      border-right: 0 none;
      border-bottom: var(--bs-red-500) solid 1px;
    }
  }

  &.plaintext {
    border: 0;
  }

  /* Checkboxes and radios */
  &[type=checkbox],
  &[type=radio] {
    font-family: var(--brand-icon-font-family);
    color: var(--brand-color-gunsmoke);
    font-weight: 300;

    &:focus {
      color: var(--brand-color-sushi);
      font-weight: bold;
      box-shadow: none;
      outline: none;
    }

    &:before {
      /* Forcefully hide the control but still keep it focusable */
      background-image: url('../../assets/white-1.png');

      /* Fixes overlap */
      font-size: 110%;
    }

    &[disabled] {
      opacity: 0.32;
    }
  }

  &[type=checkbox] {
    &:before {
      content: '\f0c8';
    }

    &:checked:before {
      content: '\f14a';
    }
  }

  &[type=radio] {
    &:before {
      content: '\f111';
      margin-left: -1px;
    }

    &:checked:before {
      content: '\f058';
    }
  }

  /* Auto width */
  &.autoWidth {
    width: auto;
  }
  
  .form-control {
    font-size: var(--brand-component-size-md);

    /* Separate border properties on purpose */
    border-color: var(--brand-color-pumice);
    border-width: 2px;

    &:focus {
      border-top: 0 none;
      border-left: 0 none;
      border-right: 0 none;
      border-bottom: var(--bs-smappee-500) solid 1px;
    }

    /* Separate rules required for each browser, otherwise ignored by all */
    &:placeholder {
      color: var(--bs-neutral-300);
    }

    &:-moz-placeholder {
      color: var(--bs-neutral-300);
    }

    &::-moz-placeholder {
      color: var(--bs-neutral-300);
    }

    &:-ms-input-placeholder {
      color: var(--bs-neutral-300);
    }

    &::-webkit-input-placeholder {
      color: var(--bs-neutral-300);
    }
  }

  .react-datepicker-wrapper {
    width: 100%;

    .react-datepicker__input-container {
      display: block;
    }
  }
}
