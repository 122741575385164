.modalFooter {
  justify-content: space-between;

  .content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
    width: 100%;

    > div {
      width: 100%;
      flex-grow: 1;
    }
    > select {
      margin: 0;
    }
  }
  .linkBtn {
    padding: 0.34375rem 0.75rem;
  }
}