.wrapper {
  width: 100%;
  box-sizing: border-box;
  padding: 0 4px;
}
.color {
  height: 30px;
  border-radius: 0.25rem;
}

.swatch {
  padding: 5px;
  background: #fff;
  border-radius: 0.25rem;
  box-shadow: 0 0 0 1px rgba(0,0,0,.1);
  display: inline-block;
  cursor: pointer;
  box-sizing: border-box;
}

.popover {
  position: absolute;
  z-index: 2;
}

.cover {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}