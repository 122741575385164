@import '../../components/variables.scss';

.notFound {
  position: absolute;
  width: calc(100% - calc(2 * var(--brand-card-border-width)));
  height: calc(100% - calc(2 * var(--brand-card-border-width)));
  top: var(--brand-card-border-width);
  left: var(--brand-card-border-width);
  background-color: rgba(#fff, 0.80);

  /* Center horizontally and vertically */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10px;

  :global(.fal) {
    font-size: 36px;
    padding: 15px;
  }
}
