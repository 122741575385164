.modal-header .close:hover {
	background-color: #fdf7f2 !important;
}

.nav-tabs .nav-link {
  color: hsl(var(--neutral-700));
	margin-bottom: 0 !important;
	border: 1px solid transparent;
	text-decoration: none;
}

.nav-tabs .nav-link:hover {
	border-color: transparent;
	background-color: hsl(var(--neutral-050));
	border-radius: unset;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
	color: hsl(var(--neutral-700));
	background-color: hsl(var(--white));
	border-color: transparent transparent hsl(var(--neutral-700));
	border-bottom: solid 2px;
}

/* Other bootstrap overrides */

/* Alert */
.alert {
	position: relative;
	padding: 1rem 0.75rem;
	border-radius: 0.25rem;
	border: none;
	margin-bottom: 1rem;
}
.alert-success, .alert-danger, .alert-warning, .alert-info, .alert-secondary {
	color: #333334;
}
.alert-success {
	background-color: #e3f3f1; /* --bs-green-100: #e3f3f1; */
	border-color: #e3f3f1;
}
.alert-danger {
	background-color: #f9dae3; /* --bs-red-200: #f9dae3; */
	border-color: #f9dae3;
}
.alert-warning {
	background-color: #fff1d0; /* --bs-yellow-100: #fff1d0; */
	border-color: #fff1d0;

}
.alert-info {
	background-color: #eeedf6; /* --bs-blue-100: #eeedf6; */
	border-color: #eeedf6;
}
.alert-secondary { /* "neutral" variant name is mapped to Bootstrap's "secondary" */
	background-color: #f5ede9; /* --bs-neutral-100: #f5ede9; */
	border-color: #f5ede9;
}