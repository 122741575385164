.checkboxCell {
  padding-right: 4px;
  position: relative;
}

.checkboxCellCheckbox {
  margin-left: 0;
  margin-top: -0.3em;
  position: 'inherit';
}

.checkboxCellCheckbox::before {
  position: absolute;
  top: -0.4em;
}
