@import '../../components/variables.scss';

.card {
  height: 100%;
  position: relative;
  background-color: var(--brand-color-white);
  border: unset;
  flex-flow: column;
  display: flex;
  flex: 1;
  box-shadow: var(--shadow-low);
  border-radius: unset;
}

.header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  width: auto;
  max-width: calc(100% - 5.375rem); /* 5.375rem = 86px => paddingLeft(24) + SettingsButton(50) + gap(12) */
  gap: 0.75rem;
  padding: 1.5rem 0 0.5rem 1.5rem;
}

.body {
  padding: 0.5rem 1.5rem 1.5rem 1.5rem;
  .nav-tabs {
    .nav-link {
      color: var(--bs-neutral-700) !important;
      font-weight: 400 !important;
      margin-bottom: 0 !important;
      border: 1px solid transparent !important;
      border-radius: 0 !important;
      &.active {
        color: var(--bs-neutral-700) !important;
        font-weight: 700 !important;
        background-color: var(--bs-white) !important;
        border-color: transparent transparent var(--bs-neutral-700) !important;
        border-bottom: solid 2px !important;
      }
      &:hover {
        border-color: transparent !important;
        background-color: var(--bs-neutral-050) !important;
        border-radius: unset !important;
      }
    }
    .nav-item {
      &.show {
        .nav-link {
          color: var(--bs-neutral-700) !important;
          font-weight: 400 !important;
          margin-bottom: 0 !important;
          border: 1px solid transparent !important;
          border-radius: 0 !important;
          &.active {
            color: var(--bs-neutral-700) !important;
            font-weight: 700 !important;
            background-color: var(--bs-white) !important;
            border-color: transparent transparent var(--bs-neutral-700) !important;
            border-bottom: solid 2px !important;
          }
          &:hover {
            border-color: transparent !important;
            background-color: var(--bs-neutral-050) !important;
            border-radius: unset !important;
          }
        }
      }
    }
  }
}

.footer {
  padding: 0.5rem 1.5rem 1.5rem 1.5rem;
}

.header {
  // width:inherit;
  h3 {
    font-size: var(--brand-component-size-lg);
    margin-bottom: 0;
    display: flex;
    align-items: center;
    line-height: 2rem;
    font-family: var(--brand-font-heading) !important;
    font-weight: 700 !important;
  }

  span:nth-child(2) {// Style the ChargingStation ParentButton link
    display: flex;
    align-items: baseline;
    padding-left: 0.25em;

    button {
      margin-bottom: 0 !important;
      margin-left: 0.5rem !important;
    }
  }

  .name {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 2.5rem;
    max-height: 2.5rem;
    z-index: 1;
    overflow: hidden;
    min-width: 10rem; /* 160px */
    /* max-width: 15rem; */ /* 240px */
    width: auto;
    margin-right: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    span {
      /* max-width: 15rem; */ /* 240px */
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .headerActions {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    flex: 1;
    padding-right: 1.5rem;
    gap: 0.75rem;

    select {
      width: auto;
      max-width: 200px;
      margin-bottom: 0;
    }

    input {
      margin-bottom: 0;
    }
  }

  .settings {
    z-index: 1;
  }

  .settingsFiller {
    /* Adjust to normal size of settings button */
    height: 43px;
  }
}

.body {
  flex: 1;
  display: flex;
  flex-flow: column;
  overflow: auto;
  padding: 0.5rem 1.5rem 1.5rem 1.5rem;
}

.center {
  position: absolute;
  width: calc(100% - calc(2 * var(--brand-card-border-width)));
  height: calc(100% - calc(2 * var(--brand-card-border-width)));
  top: var(--brand-card-border-width);
  left: var(--brand-card-border-width);
  background-color: rgba(#fff, 0.8);

  /* Center horizontally and vertically */
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  :global(.fal) {
    font-size: 36px;
    padding: 15px;
  }

  p {
    max-width: 50%;
  }
}

.lockedLocation {
  overflow: hidden;
  margin-left: 15px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

:global {
  .card-draggable-handle {
    position: absolute;
    width: calc(100% - calc(2 * var(--brand-card-border-width)));
    height: calc(100% - calc(2 * var(--brand-card-border-width)));
    top: var(--brand-card-border-width);
    left: var(--brand-card-border-width);
    //background-color: rgba(var(--brand-color-white), 0.80);
    background-color: var(--brand-color-white);
    opacity: 0.7;
    z-index: 1;

    /* Center horizontally and vertically */
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    .card-draggable-handle-inner {
      display: inline-block;
      font-size: 36px;
      padding: 15px;
    }
  }
}