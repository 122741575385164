@import '../../components/variables.scss';

.stretch {
  flex: 1;
  display: flex;
  overflow-y: auto;
  flex-flow: column;
}

.activeRow {
  font-weight: bold;
}

.filterPanel {
  max-width: 500px;
  width: 80%;
  min-width: 300px;
  display: block;
}

.wide .filterPanel {
  width: 100%;
  max-width: 900px;
}

.filterPanelWrapper {
  display: flex;
  position: absolute;
  left: 10px;
  right: 10px;
  top: 4rem;
  z-index: 20;
  align-items: center;
  flex-direction: column;
}
