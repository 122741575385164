.outer {
  width: 50%;
  padding: 3px;
  height: 64px;
}

.wrapper {
  cursor: pointer;
  width: 100%;
  height: 100%;
  color: white;

  display: flex;
  align-items: center;
  justify-content: center;
}

.inner {
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 4px;
  padding-right: 4px;
}

.listOuter {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.listInner {
  display: flex;
  flex-wrap: wrap;
  padding: 1em;
  width: 100%;
}
