.pricingGroupFooter {
  margin-top: 32px;
  align-items: flex-end;
}

.pricingGroupScrollArea {
  padding: 0.75rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.375rem;
  height: 12.25rem;
  overflow-y: auto;
  width: 100%;
  max-width: 275px;

  > *:not(:last-child) {
    border-bottom-width: 1px;
    border-bottom-color: #e2e8f0;
  }
}

.pricingGroupToken {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.pricingGroupCustomContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  > *:not(:last-child) {
    margin-right: 2rem;
  }

  > * {
    width: 100%;
  }
}

.pricingGroupCustomAddForm {
  display: flex;
  flex-direction: column;
}

.bulkImportContainer {
  margin-left: -0.75rem;
}
