.scheduleDay {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (min-width: 640px) {
    flex-direction: row;
    padding-left: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.scheduleDay__day {
  display: flex;
  height: 36px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.scheduleDay__day__label {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;

  > *:not(:last-child) {
    margin-right: 0.5rem;
  }

  > span {
    display: inline-block;
    min-width: 88px;
    font-size: 0.875rem;
    text-transform: capitalize;
  }
}

.scheduleDay__dayRanges {
  display: flex;

  @media (min-width: 640px) {
    margin-left: 0.5rem;
  }
}

.dayRanges__wrapper {
  display: flex;

  @media (min-width: 640px) {
    margin-left: 0.5rem;
  }
}

.dayRanges__container {
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;

  &:last-child {
    margin-bottom: 0;
  }
}

.dayRanges__addButton {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.timeRangeField {
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
  align-items: center;
  font-size: 0.9375rem !important;
  line-height: 1.375rem !important;
}

.timeRangeField__spacer {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  width: 2px;
  align-self: center;
}

.timeRangeField__input {
  width: 100px;
  display: inline-block;
  padding: 0.5rem 0 !important;
  margin-bottom: 0 !important;
}

.removeTimeButton {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  border: none;
  color: #e74c3c;
}

.schedule {
  width: 100%;
}
