.value {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  font-size: 1.4375rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 2.5rem !important;
  min-height: 2.5rem !important;
  gap: 0.5rem;
  h2 {
    margin: 0;
  }
}

.title {
  font-size: 1.1875rem !important;
  margin: 0 !important;
  line-height: 1.375rem !important;
}

.subTitle {
  font-size: 0.9375rem !important;
  line-height: 1.375rem !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.header {
  display: flex;
  flex-direction: column;
  padding: 0.5rem 4rem 0.5rem 0.5rem;
  width: 100%;
  min-height: 4.875rem !important;
  max-width: calc(100% - 0.5rem);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
