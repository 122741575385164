@import './fonts/SmappeeIcons/_Custom.scss';
@font-face {
  font-family: "Inter";
  src: url("./fonts/Inter/Inter-Regular.woff2") format("woff2");
  font-weight: 400;
  font-display: swap
}
@font-face {
  font-family: "Inter";
  src: url("./fonts/Inter/Inter-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-display: swap;
}
@font-face {
  font-family: "Inter";
  src: url("./fonts/Inter/Inter-Bold.woff2") format("woff2");
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: 'Soleil', sans-serif;
  font-weight: 700;
  src: url("./fonts/Soleil/Soleil-Bold.otf");
}