.lightButton {
  font-family: 'Inter', sans-serif;
  font-weight: 200;
}

.primaryButton,
.secondaryButton,
.linkButton,
.lightButton {
  padding: 0.5rem 1.25rem; // 8px 20px
  font-size: 0.9375rem; // 15px : 16px
  font-weight: 400;
  line-height: 1.375rem; // 22px : 16
  cursor: pointer;
  border: 0;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  &:global(.lg) {
    padding: 8px 16px;
    font-size: 0.9375rem; /* 15px -- 15 : 16 */
  }

  &:global(.sm) {
    padding: 4px 8px;
    font-size: 0.8125rem; /* 13px -- 13 : 16 */
  }

  &.withoutPadding {
    padding: 0;
  }

  &:disabled {
    cursor: default;
  }
}
.primaryButton,
.secondaryButton {
  &:focus,
  &:global(.active):focus {
    box-shadow: unset !important;
  }
}
.lightButton,
.linkButton {
  &:focus,
  &:global(.active):focus {
    box-shadow: unset !important;
  }
}

.primaryButton {
  color: $buttonBackgroundColor;
  background-color: $buttonForegroundColor;
  border: 1px solid $buttonForegroundColor;
  font-weight: 700;

  &:hover,
  &:not([disabled]):not(.disabled):active {
    background-color: $buttonForegroundDarker;
    border: 1px solid $buttonForegroundDarker;
  }

  &:focus,
  &:not([disabled]):not(.disabled):active {
    border: 1px solid $buttonForegroundDarker;
    box-shadow: unset !important;
    background-color: lighten($buttonForegroundColor, 10%);
  }

  &.disabled {
    background-color: $buttonForegroundColor;
    border: 1px solid $buttonForegroundColor;
    opacity: 0.3;
  }
}

.secondaryButton {
  font-weight: 400;
  border: solid 1px var(--bs-neutral-200);
  background-color: var(--bs-white);
  border-radius: 9999px !important;
  color: var(--bs-neutral-700);
  box-shadow: unset !important;
  // color: $buttonForegroundColor;
  // background-color: $buttonBackgroundColor;
  // border: 1px solid $buttonForegroundColor;

  &:hover,
  &:not([disabled]):not(.disabled):active {
    // background-color: $buttonForegroundDarker;
    // border: 1px solid $buttonForegroundDarker;
    color: var(--bs-neutral-700) !important;
    background-color: var(--bs-neutral-100);
    border: solid 1px var(--bs-neutral-300);
  }

  &:focus,
  &:not([disabled]):not(.disabled):active,
  &:not([disabled]):not(.disabled).active {
    // border: 1px solid $buttonForegroundDarker;
    // box-shadow: unset !important;
    color: var(--bs-neutral-700) !important;
    border: solid 1px var(--bs-neutral-300);
    background-color: var(--bs-white);
    // background-color: $buttonForegroundColor;
  }

  &.disabled {
    // color: $buttonForegroundColor;
    // background-color: $buttonBackgroundColor;
    // border: 1px solid $buttonForegroundColor;
    color: var(--bs-neutral-700);
    background-color: var(--bs-white);
    border: solid 1px var(--bs-neutral-200);
    opacity: 0.3;
  }
}

:global(.show) > .secondaryButton:not([disabled]):not(.disabled) {
  // background-color: $buttonForegroundDarker;
  // border: 1px solid $buttonForegroundDarker;
  background-color: var(--bs-neutral-100);
  border: solid 1px var(--bs-neutral-300);
}
.editButton {
  color: $buttonForegroundColor;
  text-overflow: unset;
  width: 1.5rem;
  height: 1.5rem;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.linkButton {
  color: $buttonForegroundColor;

  &:hover {
    color: $buttonForegroundDarker;
  }

  &:focus {
    text-decoration: underline;
  }

  &.disabled {
    color: $buttonForegroundColor;
    opacity: 0.3;

    &:hover {
      text-decoration: none;
    }
  }

  /* Underlines icons for links */
  &[href]:focus span,
  &[href]:hover span {
    text-decoration: underline;
  }

  &:global(.active) {
    font-weight: bold;
  }
}

.lightButton {
  color: var(--brand-color-aztec);
  background-color: $buttonBackgroundColor;
  box-shadow: none;
  outline: none;

  &:hover,
  &:not([disabled]):not(.disabled):hover {
    color: var(--brand-color-pumice);
    background-color: $buttonBackgroundColor;
    outline: none;
  }

  &:focus,
  &:not([disabled]):not(.disabled):hover {
    //color: var(--brand-color-pumice);
    box-shadow: none;
    outline: none;
  }
}
.boardMenu {
  background-color: $menuBackgroundColor;
  // border-right: var(--brand-card-border-width) solid var(--brand-color-pumice);
  border-right: 0 none;
}

.boardMenuTitle {
  color: $menuTitleColor;
  font-size: 1.1875rem;
  text-transform: none;
}

.boardMenuItem {
  color: $menuTextColor;

  &:hover,
  &:focus {
    background-color: $menuHoverBackgroundColor;
    box-shadow: none !important;
  }

  &.active {
    color: $menuActiveTextColor;
    background-color: $menuActiveBackgroundColor;

    &:hover,
    &:focus {
      background-color: $menuActiveHoverBackgroundColor;
    }
  }
}

.boardMenuAddBoard {
  margin: 15px 5px;
  color: $menuTextColor;
  background-color: transparent;
  border: 1px solid $menuTextColor;
  text-align: center;
  flex-shrink: 0;

  &:hover,
  &:focus {
    //color: var(--brand-color-pumice);
    //border-color: var(--brand-color-pumice);
    //color: $buttonForegroundDarker;
    //border-color: $buttonForegroundDarker;
    //background-color: var(--brand-color-white);
    background-color: $buttonForegroundDarker;
    // border: 2px solid $buttonForegroundDarker;
    border: 1px solid $buttonForegroundDarker;
  }
}

.boardNavigationActions {
  // display: flex;
  // justify-content: flex-end;
  // background-color: $menuActionsBackgroundColor;

  // button {
  //   border: none;
  //   border-radius: 0;
  //   background-color: transparent;
  //   color: $menuActionsTextColor;

  //   &:hover {
  //     color: $menuBackgroundColor;
  //     background-color: var(--brand-color-white);
  //   }
  // }
}

li.userMenu {
  color: var(--brand-color-aztec);
  font-weight: normal;
  font-size: 30px;

  a.userMenuToggle {
    color: var(--brand-color-aztec);
    padding: 12px 16px !important;
    font-size: 30px;

    &:focus {
      color: var(--brand-color-pumice);
      box-shadow: none;
      outline: none;
    }

    &:hover {
      color: var(--brand-color-pumice);
      background-color: transparent;
    }
  }

  &:hover,
  &.active:hover {
    color: var(--brand-color-pumice) !important;

    a.userMenuToggle {
      color: var(--brand-color-pumice);
    }
  }

  &:focus {
    color: var(--brand-color-pumice);
    box-shadow: none;
    outline: none;
  }

  &:not([href]):not([tabindex]) {
    color: var(--brand-color-pumice);
  }

  button {
    padding: 0;
  }
}

.dropdownItem {
  cursor: pointer;

  &:active {
    background-color: $buttonForegroundDarker;
  }

  &:focus {
    box-shadow: none;
    outline-color: $buttonForegroundDarker;
  }

  &.disabled {
    background-color: var(--brand-color-white);
  }
}

.itemsPerPageSelect {
  color: var(--brand-color-gunsmoke);
  border-color: $buttonForegroundColor;
}

:global(.form-control) {
  &:focus {
    border-top: 0 none !important;
    border-left: 0 none !important;
    border-right: 0 none !important;
    border-bottom: var(--bs-smappee-500) solid 1px !important;
    box-shadow: unset !important;
  }
}

:global(.navbar-nav) {
  .lightButton {
    background-color: transparent;
    color: $headerButtonForegroundColor;
  }

  .primaryButton {
    color: $buttonBackgroundColor;
    background-color: $buttonForegroundColor;
  }
}