/*
  Icon Font: SmappeeIcons
*/

@font-face {
  font-family: "SmappeeIcons";
  src: url("./SmappeeIcons.eot");
  src: url("./SmappeeIcons.eot?#iefix") format("embedded-opentype"),
       url("./SmappeeIcons.woff2") format("woff2"),
       url("./SmappeeIcons.woff") format("woff"),
       url("./SmappeeIcons.ttf") format("truetype"),
       url("./SmappeeIcons.svg#SmappeeIcons") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "SmappeeIcons";
    src: url("./SmappeeIcons.svg#SmappeeIcons") format("svg");
  }
}

[data-icon]:before { content: attr(data-icon); }

[data-icon]:before,
.si-achievement:before,
.si-activity-appliance:before,
.si-activity-home:before,
.si-always-on:before,
.si-apartment:before,
.si-appliances:before,
.si-appliances-filled:before,
.si-appliances-filled-notif:before,
.si-appliances-notif:before,
.si-barcode:before,
.si-bluetooth:before,
.si-clamp:before,
.si-clock:before,
.si-co2:before,
.si-compare:before,
.si-configuration:before,
.si-control:before,
.si-control-filled:before,
.si-control-filled-notif:before,
.si-control-notif:before,
.si-detect:before,
.si-electricity:before,
.si-electricity-filled:before,
.si-error:before,
.si-expert:before,
.si-export:before,
.si-gas:before,
.si-gas-filled:before,
.si-house:before,
.si-import:before,
.si-import-export:before,
.si-info:before,
.si-internet:before,
.si-location:before,
.si-log-out:before,
.si-mail:before,
.si-moon:before,
.si-no-bluetooth:before,
.si-notifications:before,
.si-onoff:before,
.si-password:before,
.si-piggy-bank:before,
.si-profile:before,
.si-profile-filled:before,
.si-profile-filled-notif:before,
.si-profile-thicker-notif:before,
.si-repeat-installation:before,
.si-schedule:before,
.si-smappee:before,
.si-smappee-device:before,
.si-smappee-gas-water:before,
.si-smappee-leaf:before,
.si-smappee-plug:before,
.si-smappee-pro:before,
.si-solar:before,
.si-solar-filled:before,
.si-solarpanels:before,
.si-star:before,
.si-sun:before,
.si-sunrise:before,
.si-sunset:before,
.si-support:before,
.si-survey:before,
.si-telephone:before,
.si-thermostat:before,
.si-threshold:before,
.si-thumbs-down:before,
.si-thumbs-down-thick:before,
.si-thumbs-up:before,
.si-thumbs-up-thick:before,
.si-trash:before,
.si-triangle:before,
.si-usage:before,
.si-usage-filled:before,
.si-usage-filled-notif:before,
.si-usage-notif:before,
.si-video-guide:before,
.si-water:before,
.si-water-filled:before,
.si-wifi:before {
  display: inline-block;
  font-family: "SmappeeIcons";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

.si-achievement:before { content: "\f100"; }
.si-activity-appliance:before { content: "\f101"; }
.si-activity-home:before { content: "\f102"; }
.si-always-on:before { content: "\f103"; }
.si-apartment:before { content: "\f104"; }
.si-appliances:before { content: "\f105"; }
.si-appliances-filled:before { content: "\f106"; }
.si-appliances-filled-notif:before { content: "\f107"; }
.si-appliances-notif:before { content: "\f108"; }
.si-barcode:before { content: "\f109"; }
.si-bluetooth:before { content: "\f10a"; }
.si-clamp:before { content: "\f10b"; }
.si-clock:before { content: "\f10c"; }
.si-co2:before { content: "\f10d"; }
.si-compare:before { content: "\f10e"; }
.si-configuration:before { content: "\f10f"; }
.si-control:before { content: "\f110"; }
.si-control-filled:before { content: "\f111"; }
.si-control-filled-notif:before { content: "\f112"; }
.si-control-notif:before { content: "\f113"; }
.si-detect:before { content: "\f114"; }
.si-electricity:before { content: "\f115"; }
.si-electricity-filled:before { content: "\f116"; }
.si-error:before { content: "\f117"; }
.si-expert:before { content: "\f118"; }
.si-export:before { content: "\f119"; }
.si-gas:before { content: "\f11a"; }
.si-gas-filled:before { content: "\f11b"; }
.si-house:before { content: "\f11c"; }
.si-import:before { content: "\f11d"; }
.si-import-export:before { content: "\f11e"; }
.si-info:before { content: "\f11f"; }
.si-internet:before { content: "\f120"; }
.si-location:before { content: "\f121"; }
.si-log-out:before { content: "\f122"; }
.si-mail:before { content: "\f123"; }
.si-moon:before { content: "\f124"; }
.si-no-bluetooth:before { content: "\f125"; }
.si-notifications:before { content: "\f126"; }
.si-onoff:before { content: "\f127"; }
.si-password:before { content: "\f128"; }
.si-piggy-bank:before { content: "\f129"; }
.si-profile:before { content: "\f12a"; }
.si-profile-filled:before { content: "\f12b"; }
.si-profile-filled-notif:before { content: "\f12c"; }
.si-profile-thicker-notif:before { content: "\f12d"; }
.si-repeat-installation:before { content: "\f12e"; }
.si-schedule:before { content: "\f12f"; }
.si-smappee:before { content: "\f130"; }
.si-smappee-device:before { content: "\f131"; }
.si-smappee-gas-water:before { content: "\f132"; }
.si-smappee-leaf:before { content: "\f134"; }
.si-smappee-plug:before { content: "\f135"; }
.si-smappee-pro:before { content: "\f136"; }
.si-solar:before { content: "\f137"; }
.si-solar-filled:before { content: "\f138"; }
.si-solarpanels:before { content: "\f139"; }
.si-star:before { content: "\f13a"; }
.si-sun:before { content: "\f13b"; }
.si-sunrise:before { content: "\f13c"; }
.si-sunset:before { content: "\f13d"; }
.si-support:before { content: "\f13e"; }
.si-survey:before { content: "\f13f"; }
.si-telephone:before { content: "\f140"; }
.si-thermostat:before { content: "\f141"; }
.si-threshold:before { content: "\f142"; }
.si-thumbs-down:before { content: "\f143"; }
.si-thumbs-down-thick:before { content: "\f144"; }
.si-thumbs-up:before { content: "\f145"; }
.si-thumbs-up-thick:before { content: "\f146"; }
.si-trash:before { content: "\f147"; }
.si-triangle:before { content: "\f148"; }
.si-usage:before { content: "\f149"; }
.si-usage-filled:before { content: "\f14a"; }
.si-usage-filled-notif:before { content: "\f14b"; }
.si-usage-notif:before { content: "\f14c"; }
.si-video-guide:before { content: "\f14d"; }
.si-water:before { content: "\f14e"; }
.si-water-filled:before { content: "\f14f"; }
.si-wifi:before { content: "\f150"; }
