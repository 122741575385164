@import '../../components/variables.scss';

.configStatus {
  display: flex;
  position: relative;

  :global {
    .form-group {
      margin-right: 30px;
    }
  }
}

table {
  tr.invalidRow {
    background-color: rgba(var(--brand-color-persian-red), 0.2);

    &:hover {
      background-color: rgba(var(--brand-color-persian-red), 0.3);
    }
  }

  tr.rowSpacing {
    td {
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }
}

td.groupColumn {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    display: inline-block;
  }
}

table.subRows {
  background-color: var(--brand-color-alabaster) !important;
  border: solid 2px var(--brand-color-pumice);

  tr {
    th, td {
      padding-top: 2px;
      padding-bottom: 2px;
    }

    th:first-of-type, td:first-of-type {
      padding-left: 10px;
    }
  }

  thead.subRowHeader {
    th {
      border-bottom: none !important;
    }
  }
}

.sensorReversed {
  text-align: center;

  th, td {
    padding-left: 0;
  }
}

.addButtons {
  position: absolute;
  top: 5px;
  right: 5px;
}
