.card {
  background-color: #cccccc;
  border: 1px solid #e2e8f0;
  border-radius: calc(0.25rem - 1px);
  background-color: rgba(0, 0, 0, 0.03);
  padding: 0.5rem;
}

.title {
  font-weight: bold;
  font-size: 0.95rem;
}

.header {
  margin-top: 0.35rem;
  font-size: 0.9rem;
}

.prefix {
  color: gray;
  font-size: 0.9rem;
}

.newPrice {
  font-size: 1.125rem;
}

.oldPrice {
  color: gray;
  display: inline-block;
  padding-left: 1rem;
  font-size: 0.9rem;
}

.strikethrough {
  text-decoration: line-through;
}