.smallButton {
  font-size: 1.3rem;
  padding: 0 0.6rem;
  border: 2px solid #155a55;

  margin-right: 5px !important;
}

.smallButton2 {
  font-size: 1.1rem;
  padding: 0 0.4rem;
  border: 2px solid #155a55;

  margin-right: 5px !important;
}
