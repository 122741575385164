.infoView, .errorView {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 0.8rem;

  position: relative;
  bottom: 8px;

  .info {
    flex-grow: 1;

    h2 {
      font-size: 20px;
      margin-bottom: 0.2rem;
    }

    h3 {
      color: var(--brand-color-gunsmoke);
      font-size: 16px;
      margin-bottom: 0;
    }
  }
}

.errorView {
  color: red;
}

.infoView {
  color: var(--brand-color-gunsmoke);
}
