@import '../../../components/variables.scss';

.picture {
  img {
    width: 100%;
  }
}

.navigation {
  padding-top: 0;

  :global {
    span.fa-chevron-left {
      margin-right: 15px;
    }

    span.fa-chevron-right {
      margin-left: 15px;
    }
  }
}
