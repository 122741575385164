
.arrows {
  flex: 1;
  padding-left: 10px;
  padding-right: 10px;
  display: inline-block;
  cursor: move;

  &:focus {
    outline: none;
  }
}
