@import '../../components/variables.scss';

.stretch {
  flex: 1;
  display: flex;
  overflow-y: auto;
  flex-flow: column;
}

.chart {

}
