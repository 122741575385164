.load {
  display: flex;
  flex-direction: row;
  min-width: 350px;
  align-items: center;
  overflow: hidden;
  padding: 8px;
  height: 56px !important;
}

.loadName {
  flex-grow: 1;
  flex-shrink: 1;
  padding-right: 10px;
}

.loadCurrent {
  flex-shrink: 0;
  flex-grow: 0;

  display: flex;
  flex-direction: row;

  input {
    /* padding-top: 0.2rem; */
    /* padding-bottom: 0.2rem; */
    /* padding-left: 0.5rem; */
    /* padding-right: 0.5rem; */
    /* line-height: 1.2; */
    width: 55px !important;
    flex-shrink: 0;
    flex-grow: 0;
  }

  :global(.input-group) {
    margin-right: 10px;
  }

  button {
    width: 40px;
    flex-shrink: 0;
    border-width: 1px;
    margin: 0 0.5rem;
    &:first-of-type {
      margin-left: 0;
    }
    &:last-of-type {
      margin-left: 0;
      margin-right: 0;
    }

    i {
      width: 10px;
    }
  }
}

:global {
  .rst__node {
    height: 76px !important;
  }
  .rst__rowLabel {
    padding-right: 0;
  }

  .rst__rowContents {
    padding-right: 1px;
    border-radius: 9999px;
    box-shadow: unset;
  }

  .rst__moveHandle {
    flex-shrink: 0;
    height: 56px;
    max-height: 56px;
    width: 56px;
    max-width: 56px;
    border-radius: 9999px;
    margin-right: 0.25rem;
  }
}