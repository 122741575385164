.button {
  overflow: hidden;
  text-overflow: clip;

  :global {
    .fa-sync {
      font-size: 1rem;
      padding: 0;
      margin-left: 4px;
    }
  }
}
