.timedBasedNumberInput {
  display: flex;
  flex-direction: column;
  gap: 0rem;
}

.timedBasedNumberInputRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
  align-items: center;
}

.timedBasedNumberInputRowFields {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: flex-start;
  width: 100%;
}

.timedBasedNumberInputField {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  flex-grow: 1;
}

.timeComponentsTable {
  width: 100%;

  :global(.form-group) {
    padding: 0;
  }

  label {
    margin-bottom: 0;
  }

  input {
    margin-bottom: 0;
  }
}

.costColumn {
  padding-bottom: 4px;
  padding-right: 8px;
  vertical-align: bottom;
  padding-bottom: 12px;
  margin-bottom: 12px;

  input[type='number'] {
    -moz-appearance: textfield;
  }
}

.timeColumn {
  width: 180px;
  padding-bottom: 4px;
  vertical-align: top;
}

.deleteColumn {
  width: 30px;
  vertical-align: bottom;
  padding-bottom: 18px;
  text-align: center;
  padding-left: 6px;
}

.tariffsDeleteButton {
  color: red;
  padding: 8px;
  background: none;
  border: none;
}

.error {
  padding-bottom: 0.5rem;

  :global(.invalid-feedback) {
    margin-top: 0.15rem;
  }
}

.basePrice {
  color: gray;
  font-size: 0.9rem;
  text-decoration: line-through;
}
