.settingsHandle {
  --bs-neutral-050: #fdf7f2;
  z-index: 100;
  background-color: white;
  border-radius: 9999px;
  box-shadow: var(--shadow-low);
  width: 2.75rem;
  height: 2rem;

  &:hover {
    background-color: var(--bs-neutral-050);
  }
}
