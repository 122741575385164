@import '../components/variables.scss';

$buttonForegroundColor: #333334; /* var(--bs-neutral-700) */
$buttonBackgroundColor: #FFFFFF; /* var(--bs-white) */

$menuBackgroundColor: #FFFFFF; /* var(--bs-white) */
$menuTextColor: var(--brand-color-eden);
$menuTitleColor: var(--bs-black);
$menuHoverBackgroundColor: transparent;

$menuActiveTextColor: var(--brand-color-eden);
$menuActiveBackgroundColor: transparent;
$menuActiveHoverBackgroundColor: transparent;

$menuActionsTextColor: var(--brand-color-eden);
$menuActionsBackgroundColor: transparent;

$buttonForegroundDarker: darken(#333334, 40%);

$headerButtonForegroundColor: var(--brand-color-aztec);

@import './Styles--common.module.scss';
