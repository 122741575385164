@import './variables.scss';

.downToggleButton {
  position: absolute;
  right: 0.1em;
  padding: 0.4em;
  color: var(--bs-text-primary);

  /*background-color: #e1e1e1;
  padding-top: 0.7em;
  border: 1px solid #ccc;
  font-size: 12px;
  height: 100%;
  border-top-right-radius: 0.25em;
  border-bottom-right-radius: 0.25em;*/
}

.clear {
  position: absolute;
  right: 0.1em;
  padding: 0.4em;
  color: #888;
  cursor: pointer;
}

.clearValue {
  position: absolute;
  right: 1.1em;
  padding: 0.4em;
  color: #888;
  cursor: pointer;
}

.dropdown {
  max-height: 400px;
  overflow-y: auto;
  min-width: 100%;
  max-width: 286px;

  :global &.show {
    .downToggleButton {
      background-color: #E5F1FB;
    }
  }
}
