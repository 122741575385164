@import '../variables.scss';

a.dash-nav-link {
  color: var(--brand-color-aztec);
  padding: 15px !important;
  font-weight: normal;
  font-size: 20px;

  &.active {
    color: var(--brand-color-eden);
  }

  &:focus {
    color: var(--brand-color-pumice);
    box-shadow: none;
    outline: none;
  }

  &:hover,
  &.active:hover {
    color: var(--brand-color-pumice) !important;
  }
}
