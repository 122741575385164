@import '../../components/variables.scss';

.stretch {
  flex: 1;
  overflow-y: auto;
}

.form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 15px;
}

@media (--screen-xl-above) {
  .form {
    grid-template-columns: repeat(3, 1fr);
  }
}

.footerActions {
  margin-top: 15px;

  button {
    margin-right: 15px;
  }
}

.daysInput {
  padding: 1px;
}
