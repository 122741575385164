.item {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  &>div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
}

.items {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  :global(.card-header) {
    padding: 0.5rem;
  }

  :global(.card-body) {
    padding: 0.5rem;
  }
}

.header {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.extra {
  display: flex;
  align-items: center;
  gap: 1rem;

  button {
    background: none;
    border: none;
    cursor: pointer;
  }
}