.configurationInfo {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  dt {
    font-weight: bold;
    margin-top: 2px;
    margin-bottom: 2px;
    text-align: right;
  }

  dd {
    margin-top: 2px;
    margin-bottom: 2px;
  }
}
