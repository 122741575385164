@import '../../variables.scss';

.connectPricingGroupItem {
  background-image: none;
  text-align: left;
  flex: 1 1 0%;
  width: 100%;
  border-style: none;
}

.builtInBadge {
  margin-left: 0.5rem;
  background-color: #f3f4f6;
  font-size: 0.775rem;
  font-weight: 600;
  line-height: 1rem;
  text-align: center;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
}

.buttonSpacer {
  margin-left: 0.5rem;
  width: 38px;
}

.connectPricingGroupNameLocationGroup {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.connectPricingGroupAction {
  padding: 0.5rem 0.75rem;
  border: none;
  background: none;
  box-shadow: none;

  &:hover {
    color: black;
  }
}

.connectPricingGroupServiceLocationName {
  display: flex;
  margin-right: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  background-color: #f3f4f6;
  color: #000;
  width: auto;
}

.connectPricingGroupDeleteAction {
  padding: 0.5rem 0.75rem;
  border: none;
  background: none;
  box-shadow: none;
  color: red;

  &:hover {
    background-color: #fee2e2;
  }
}

.selectedPricingGroupItem {
  position: relative;
  display: flex;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  border-radius: 0.5rem;

  border: 1px solid #e5e7eb;
}

.error {
  border-color: red;
}

.selectPricingGroupItem {
  display: flex;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  min-width: 300px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-top-width: 1px;

  &:first-child {
    border-top-width: 0;
  }
}

.selectPricingGroupDropdown {
  display: flex;
  width: 100%;
  border: none;
  text-align: left;
  align-items: center;
  margin-right: 0.5rem;
  background: none;
  color: #1f2937;

  &:disabled {
    background: none;
    color: #000;
  }

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 350px;
  }
}

.pricingGroupActions {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  & > * {
    margin-left: 0.5rem;
  }

  > span {
    color: #6b7280;
    font-size: 0.775rem;
    white-space: nowrap;
    margin-right: 1rem;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }

    > span {
      display: block;
    }
  }
}

.pricingGroupDefaultPrice {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  & > * {
    margin-left: 0.5rem;
  }

  > span {
    color: #6b7280;
    font-size: 0.775rem;
    white-space: nowrap;
    margin-right: 1rem;

    > span {
      display: block;
    }
  }

  .pricingGroupPrice {
    color: gray;
  }
}

.pricingGroupActiveBadge {
  background-color: #10b981;
  color: #ffffff;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1rem;
  text-align: center;
  margin-left: 0.5rem;
}

.disabledLineCross {
  position: absolute;
  width: 95%;
  height: 1px;
  background-color: #e5e7eb;
  top: 50%;
  z-index: 1;
  left: 50%;
  transform: translate(-50%, -50%);
}

.specificPrices {
  & > * + * {
    margin-top: 0.5rem;
  }
}

.pricingGroupPrice {
  color: #10b981;
}

.pricingGroupBlocked {
  color: var(--brand-color-persian-red);
}

.quickAddOption {
  display: flex;
  background: #6b7280;
  color: #ffffff;
  border-radius: 25px;
  padding: 4px 8px;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  margin-right: 2px;
  align-items: center;
  border: none;
  cursor: pointer;

  &:hover {
    background: #111827;
  }
}
