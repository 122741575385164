@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    /* Smappee Branding Design System 2024 */
    /* Primitives */
    /* Basic */
    --white: 0, 0%, 100%;
    --natural-white: 27, 100%, 98%;
    --black: 0, 0%, 0%;

    /* Opacitiy values */
    --transparant: 0, 0%, 100%, 0;
    --light-80: 0, 0%, 100%, 0.8;
    --light-20: 0, 0%, 100%, 0.2;
    --dark-20: 240, 2%, 8%, 0.2;
    --dark-80: 240, 2%, 8%, 0.8;

    /* Neutral */
    --neutral-050: 27, 70%, 97%;
    --neutral-100: 20, 38%, 94%;
    --neutral-200: 19, 30%, 89%;
    --neutral-300: 21, 20%, 80%;
    --neutral-400: 16, 5%, 60%;
    --neutral-500: 0, 0%, 44%;
    --neutral-600: 0, 0%, 35%;
    --neutral-700: 240, 1%, 20%;
    --neutral-800: 240, 2%, 12%;
    --neutral-900: 240, 2%, 8%;

    /* Blue */
    --blue-100: 247, 33%, 95%;
    --blue-200: 223, 41%, 86%;
    --blue-300: 211, 39%, 78%;
    --blue-500: 219, 41%, 59%;
    --blue-700: 220, 41%, 39%;
    --blue-800: 223, 41%, 26%;

    /* Green */
    --green-100: 173, 40%, 92%;
    --green-200: 164, 27%, 84%;
    --green-300: 154, 24%, 80%;
    --green-500: 164, 27%, 59%;
    --green-700: 163, 27%, 35%;
    --green-800: 165, 28%, 17%;
    --toggle-green-secondary: 164, 27%, 59%;

    /* Yellow */
    --yellow-100: 42, 100%, 91%;
    --yellow-200: 40, 100%, 85%;
    --yellow-300: 44, 100%, 75%;
    --yellow-500: 40, 89%, 63%;
    --yellow-700: 36, 78%, 45%;
    --yellow-800: 36, 82%, 25%;

    /* Red */
    --red-100: 343, 69%, 95%;
    --red-200: 343, 72%, 92%;
    --red-300: 349, 91%, 82%;
    --red-500: 351, 100%, 68%;
    --red-700: 351, 57%, 49%;
    --red-800: 351, 52%, 39%;

    /* Smappee */
    --smappee-500: 76, 59%, 47%;
    --smappee-100: 85, 77%, 90%;
    --smappee-green: var(--smappee-500);

    --background: var(--white);
    --foreground: var(--neutral-700);

    --card: var(--white);
    --card-foreground: var(--neutral-700);

    --popover: var(--white);
    --popover-foreground: var(--neutral-700);

    --placeholder: var(--transparent);
    --placeholder-foreground: var(--neutral-300);

    --primary: var(--neutral-700);
    --primary-foreground: var(--neutral-100);

    --secondary: var(--smappee-500);
    --secondary-foreground: var(--white);

    --muted: var(--neutral-300);
    --muted-foreground: var(--neutral-050);

    --accent: var(--neutral-050);
    --accent-foreground: var(--black);

    --destructive: var(--red-500);
    --destructive-foreground: var(--white);
    --destructive-hover: var(--red-700);

    --switch: var(--smappee-500);
    --switch-foreground: var(--white);
    --switch-unselected: var(--muted);

    --switch-disabled: var(--primary-light-grey);
    --switch-disabled-foreground: var(--primary-light-grey);

    --switch-negative: var(--red-500);
    --switch-negative-foreground: var(--white);
    --switch-negative-muted: var(--red-300);

    --border: var(--neutral-200);
    --input: var(--neutral-050);
    --ring: var(--smappee-500);

    --radius: 0.5rem;

    /* Shadow colors */
    --shadow-dark: 0, 0%, 0%, 0.4;
    --shadow-light: 22, 81%, 18%, 0.05;

    /* Brand */
    --primary-dark-grey: var(--neutral-700);
    --primary-smappee-green: var(--smappee-500);
    --primary-light-grey: var(--neutral-200);
    --primary-natural-white: var(--neutral-050);

    --secondary-natural-grey: var(--neutral-500);
    --bs-secondary-natural-grey: var(--bs-neutral-500);
    --secondary-water: var(--blue-200);
    --bs-secondary-water: var(--bs-blue-200);
    --secondary-sun: var(--yellow-200);
    --bs-secondary-sun: var(--bs-yellow-200);
    --secondary-gas: var(--green-200);
    --bs-secondary-gas: var(--bs-green-200);
    --secondary-energy: var(--red-500);
    --bs-secondary-energy: var(--bs-red-500);
    --secondary-earth: var(--red-200);
    --bs-secondary-earth: var(--bs-red-200);

    /* Surface */
    --surface-primary: var(--white);
    --surface-primary-support: var(--neutral-050);
    --surface-focus: var(--smappee-500);
    --surface-warning: var(--yellow-500);
    --surface-negative: var(--red-500);
    --surface-disabled: var(--neutral-200);

    /* Border */
    --border-primary: var(--neutral-200);
    --border-focus: var(--neutral-700);
    --border-positive: var(--smappee-500);
    --border-warning: var(--yellow-500);
    --border-negative: var(--red-500);
    --border-disabled: var(--neutral-100);

    /* Text */
    --text-primary: var(--neutral-700);
    --text-secondary: var(--neutral-600);
    --text-focus: var(--smappee-500);
    --text-positive: var(--smappee-500);
    --text-warning: var(--yellow-700);
    --text-negative: var(--red-700);
    --text-disabled: var(--neutral-300);
  }

  .dark {
    /* Dark mode */
    --background: var(--neutral-800);
    --foreground: var(--neutral-100);

    --card: var(--neutral-800);
    --card-foreground: var(--neutral-100);

    --popover: var(--neutral-800);
    --popover-foreground: var(--white);

    --placeholder: var(--transparant);
    --placeholder-foreground: var(neutral-600);

    --primary: var(--neutral-050);
    --primary-foreground: var(--neutral-900);

    --secondary: var(--smappee-500);
    --secondary-foreground: var(--white);

    --muted: var(--neutral-700);
    --muted-foreground: var(--neutral-600);

    --accent: var(--neutral-050);
    --accent-foreground: var(--black);

    --destructive: var(--red-500);
    --destructive-foreground: var(--white);
    --destructive-hover: var(--red-300);

    --border: var(--neutral-600);
    --input: var(--neutral-800);
    --ring: var(--smappee-500);

    --switch-negative: var(--red-700);
    --switch-negative-foreground: var(--red-300);
  }

  @font-face {
    font-family: "Inter";
    src: url("../src/assets/fonts/Inter/Inter-Regular.woff2") format("woff2");
    font-weight: 400;
    font-display: swap;
  }

  @font-face {
    font-family: "Inter";
    src: url("../src/assets/fonts/Inter/Inter-SemiBold.woff2") format("woff2");
    font-weight: 600;
    font-display: swap;
  }

  @font-face {
    font-family: "Inter";
    src: url("../src/assets/fonts/Inter/Inter-Bold.woff2") format("woff2");
    font-weight: 700;
    font-display: swap;
  }

  @font-face {
    font-family: "Soleil";
    src: url("../src/assets/fonts/Soleil/Soleil-Bold.woff2") format("woff2");
    font-weight: 700;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "Soleil", sans-serif;
    font-weight: 700;
  }
}