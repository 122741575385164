@import '../../components/variables.scss';

.gauge {
  display: inline-block;
  width: 100%;
}

.gaugeInnerContainer {
  width: 50%;
  display: inline-block;
  position: relative
}

.gaugeContainer {
  flex: 1 1;
  overflow: hidden;
  text-align: center;
}
.alwaysOnContainer {
  display: flex;
  flex-flow: row;
  align-items: stretch;
}

.usageContainer {
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-flow: row;
  height: 120px;

  .water {
    color: var(--bs-secondary-water);
  }

  .gas {
    color: var(--bs-secondary-gas);
  }

  .alwaysOn {
    color: var(--bs-secondary-sun);
  }

  .water,
  .gas,
  .alwaysOn {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    margin-right: 20px;
    margin-left: 20px;
    padding-bottom: 15px;
    text-align: center;

    div {
      font-size: 32px;
      height: 32px;
      margin-bottom: 5px;
    }

    h4 {
      font-size: var(--bs-font-size-heading-04);
      color: var(--bs-text-primary);
    }

    span {
      font-size: 21px;
      color: var(--bs-secondary-natural-grey);
    }
  }

  .water div {
    color: var(--brand-color-pacific-blue);
  }

  .gas div {
    color: var(--brand-color-clementine);
  }

  .alwaysOn div {

  }
}

.gaugeIconElectricity,
.gaugeIconSolar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.5rem;
}

.gaugeIconElectricity {
  color: var(--bs-yellow-500);
}

.gaugeIconSolar {
  color: var(--bs-green-500);
}

.gaugeValue {
  font-size: 12px; /* was 42px */
  line-height: 12px;
  font-weight: normal;
  color: var(--brand-color-aztec);
  font-family: var(--brand-font-family);
}

.labelWrapper {
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.iconWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}