@import '../../components/variables.scss';

.gauge {
  flex: 1;
  display: flex;
}

.gaugeContainer {
  display: flex;
  flex-flow: row;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  position: relative;

  .legend {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    text-align: right;
    padding-right: 15px;

    div {
      white-space: nowrap;
    }
  }
}
