.infoMain {
  text-align: left; /* center */
}

.icon {
  width: 85px;
  max-height: 85px;
}

.supportTitle {
  position: relative;
}

.supportTitleContent {
  position: relative;
  padding-top: 0.25em;
  padding-bottom: 0.25em;
  display: inline-block;
}

.supportList {
  display: flex;
  list-style: none;
  justify-content: flex-start;/* center;*/
  padding: 0;
  text-align: center;
  margin-left: -1em;
  margin-right: -1em;
  margin-bottom: 0;

  li {
    margin: 1em;
    flex-shrink: 1;
  }
}

.iconWebsite {
  content: ' ';
  width: 32px;
  height: 32px;
  display: inline-block;
  background: url('./icons/internet.svg');
}

.iconPhone {
  content: ' ';
  width: 32px;
  height: 32px;
  display: inline-block;
  background: url('./icons/telephone.svg');
}

.iconMail {
  content: ' ';
  width: 32px;
  height: 32px;
  display: inline-block;
  background: url('./icons/mail.svg');
}

.iconSupport {
  content: ' ';
  width: 28px;
  height: 28px;
  display: inline-block;
  background: url('./icons/support.svg');
  position: relative;
  top: 4px;
}
