@import '../variables.scss';

:root {
  --brand-logo-width: 140px;
  --brand-logo-height: 32px;
}

a.dash-navbar-brand {
  color: var(--brand-color-white);
  margin-left: 1.5rem;

  /* Brand logo */
  background-image: url('../../assets/smappee-logo-large.svg');
  background-repeat: no-repeat;
  background-position: 0;
  background-size: var(--brand-logo-width) auto;
  width: calc(var(--brand-logo-width) + 4px);
  font-size: 2rem;
  line-height: 1;
  padding-top: 0;
  padding-bottom: 0;

  /* Hide text */
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;

  /* Spacing */

  &:focus {
    box-shadow: none;
    outline: none;
  }

  &.collapsed {
    color: var(--bs-smappee-green);
    background-image: url('../../logo/logo-mark.svg');
    background-repeat: no-repeat;
    background-position: 0;
    background-size: 1.48387097rem 2rem;
    width: 1.48387097rem;
    font-size: 2rem;
    line-height: 1;
  }
}
