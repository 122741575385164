@import '../../components/variables.scss';

.formWrapper {
  max-width: 320px;
}

.formActions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
