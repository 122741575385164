.durationInput {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;

  > * + * {
    margin-left: 0.5rem;
  }
}
