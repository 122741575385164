@import './variables.scss';

.pagination {
  .count,
  .page {
    color: var(--bs-neutral-700);
    font-size: 0.8125rem;
    white-space: nowrap;
  }

  .count {
    flex: 1;
    white-space: nowrap;
  }

  button {
    padding: 0 !important;

    &:last-of-type {
      margin-right: 0;
    }
  }
}

.itemsPerPage {
  color: var(--bs-neutral-700);
  font-size: 0.8125rem;
  display: inline-block;
  white-space: nowrap;
  
  text-overflow: ellipsis;
  overflow: hidden;
}

.pageNavBtn {
  width: 2.75rem;
  height: 2rem;
  min-width: 2.75rem;
  max-width: 2.75rem;
  max-height: 2rem;
  min-height: 2rem;
  text-overflow: unset;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}