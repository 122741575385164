/* Custom styles to remove Bootstrap form validation icons */

/* Remove the valid state icon */
.was-validated .form-control:valid,
.form-control.is-valid {
  background-image: none;
}

/* Remove the invalid state icon */
.was-validated .form-control:invalid,
.form-control.is-invalid {
  background-image: none;
}

/* Custom styles for form-control input */

/* Remove border-radius and set gray bottom border */
.form-control {
  border-radius: 0;               /* Remove border-radius */
  border-top: none;               /* Remove top border */
  border-right: none;             /* Remove right border */
  border-left: none;              /* Remove left border */
  border-bottom: 1px solid #ece1dc;  /* Set neutral-200 bottom border */
  font-size: 0.9375rem;           /* 15 pt */
  line-height: 1.375rem;          /* default: 22 pt */
  padding-top: 0.5rem;            /* default: 8 pt */
  padding-bottom: 0.5rem;         /* default: 8 pt */
  padding-left: 0;
}

.card-actions select.form-control {
  width: auto;
}

/* Change bottom border to green on focus */
.form-control:focus {
  outline: none;                  /* Remove default focus outline */
  border-bottom-color: #99C031;     /* Change bottom border to smappee-green */
}

.dash-modal .form-control {
  margin-bottom: 1rem;
}

.dash-modal .checkbox-wrapper {
  margin-bottom: 1rem;
}

.form-group {
  .input-group {
    position: relative;
    display: flex;
    align-items: center;
    .input-group-append {
      display: flex;
      position: absolute;
      right: 0;
    }
  }
}
.input-group-text {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 0.9375rem;
  font-weight: 700;
  border-radius: unset;
  background-color: unset;
  border: unset;
  /* border-bottom: 1px solid #d6c9c2; */
  line-height: 1.375rem;
  color: inherit;
  margin-bottom: 0;
  white-space: nowrap;
  padding: 0.5rem 0 0.5rem 0.75rem;
  &:focus {
    border-bottom: 1px solid #99C031;
  }
}