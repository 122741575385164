.wrapper {
  height: 100%;
  display: flex;
  flex-direction: row;
}

.left {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-right: 8px;
  overflow: hidden;
}

.right {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 8px;
  overflow: hidden;
}
