.header {
  margin-top: 0.75rem;
  margin-bottom: 0.25rem;
}

.prefix {
  color: gray;
  font-size: 0.9rem;
}

.newPrice {
  font-size: 1.125rem;
}

.oldPrice {
  color: gray;
  display: inline-block;
  padding-left: 1rem;
  font-size: 0.9rem;
}

.strikethrough {
  text-decoration: line-through;
}