.tariffsCardContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
}

.tariffsCard {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  isolation: isolate;
  border: 1px solid #cbd5e1;
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  width: 100%;
  padding: 21px 25px;

  &:hover {
    border: 1px solid #111827;
  }
}

.tariffsCardTitle {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #111827;
}

.tariffsCardDescription {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #6b7280;
}

.tariffInputContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 8px;

  padding: 8px;
  background: #f9fafb;
  border-radius: 8px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
}

.inputWrapper {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.tariffInputInput {
  width: 100%;

  :global(.form-group) {
    margin: 0;
  }
}

.tariffInputScrapped {
  width: 100%;

  label {
    margin-bottom: 0;
  }

  .tariffInputOldPrice {
    padding-top: 0;
  }

  :global(.form-group) {
    margin-bottom: 0;
  }
}

.tariffsDeleteButton {
  color: red;
  padding: 8px;
  background: none;
  border: none;
  margin-top: 50px;

  i {
    color: red;
  }

  &:hover i {
    color: #111827;
  }
}

.tariffInputOldPrice {
  color: gray;
  font-size: 0.9rem;
  padding-top: 4px;
  text-decoration: line-through;
}