.settings {
  position: absolute;
  // 15 px for a regular card
  top: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.settingsGreen {
  position: absolute;
  // 15 px for a regular card
  top: 10px;
  right: 10px;
}

.center {
  position: absolute;
  width: calc(100% - calc(2 * var(--brand-card-border-width)));
  height: calc(100% - calc(2 * var(--brand-card-border-width)));
  top: var(--brand-card-border-width);
  left: var(--brand-card-border-width);
  background-color: rgba(#fff, 0.80);
  padding: 15px;

  /* Center horizontally and vertically */
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  :global(.fal) {
    font-size: 36px;
    padding: 15px;
  }

  p {
    max-width: 50%;
  }
}
.miniCard {
  background-color: var(--brand-color-white);
  border: unset;
  box-shadow: var(--shadow-low);
}