@import '../variables.scss';

.rowActions {
  display: flex;
  justify-content: center;

  button:first-of-type {
    margin-right: 30px;
  }
}
