@import '../../components/variables.scss';

.iconColumn {
  position: absolute;
  bottom: 0;
  right: 0;

  div {
    font-size: 150px;
    color: var(--brand-color-pumice);
  }

  :global {
    div.si-smappee-device {
      width: 245px;
      height: 220px;
    }

    div.si-smappee-pro {
      width: 150px;
      height: 230px;
    }
  }

}

.actionsFiller {
  flex: 1;
}

.actionsRestart {
  margin-right: 15px;
}

.nameInput {
  display: flex;
  flex-direction: row;

  p {
    width: auto;
    padding-top: 4px;
    padding-bottom: 5px;
  }

  &.editing {
    input,
    button {
      margin-right: 15px;

      /* Maintain same height when clicking edit */
      height: 39px;
    }

    input {
      width: auto;
    }
  }
}

.serialNumber {
  color: var(--brand-color-corduroy);
}

.overview {
  display: flex;
  flex-direction: row;

  :global {
    .form-group {
      margin-bottom: 5px;
    }
  }

  .moduleRow {
    p {
      padding-top: 0;
      padding-bottom: 0;
    }

    small {
      margin-top: 0;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .firmware {
      display: flex;
      justify-content: flex-start;

      p {
        width: auto;
      }
    }
  }

  th {
    font-weight: normal;
  }

  tr {
    vertical-align: top;

    td, th {
      padding-right: 45px;

      &:first-of-type {
        max-width: 50%;
      }
    }
  }

}
