@import '../../components/variables.scss';

.inputs {
  display: flex;
  align-content: stretch;

  :global {
    .form-group {
      flex: 1;

      &:first-of-type {
        margin-right: 15px;
      }
    }
  }
}

.errorMessage {
  color: var(--brand-color-persian-red);

  :global {
    span.fal {
      margin-right: 10px;
    }
  }
}

.featureCheckbox {
  ::after {
    position: relative;
    bottom: 0.4em;
  }
}

.harmonicsTable {
  :global {
    .form-check-input {
      margin: 0;
    }
  }
}

.checkboxCell {
  padding-left: 0 !important;
  padding-right: 10px;
}
