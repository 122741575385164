@import '../../components/variables.scss';

.moduleTitle {
  display: inline-block;
  font-size: var(--brand-component-size-md);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
}

.overview {
  display: flex;
  flex-direction: row;
  overflow: auto;

  :global {
    .form-group {
      margin-bottom: 5px;
    }
  }

  .moduleRow {
    p {
      padding-top: 0;
      padding-bottom: 0;
    }

    small {
      margin-top: 0;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .firmware {
      display: flex;
      justify-content: flex-start;

      button {
        margin-top: 0.375em;
        margin-bottom: 0.375em;  
      }
      
      p {
        width: auto;
      }
    }
  }

  th {
    font-weight: normal;
  }

  tr {
    vertical-align: top;

    td, th {
      &:first-of-type {
        width: 250px;
        max-width: 50%;
      }
    }
  }
}
