.container {
  position: relative;
  width: 20rem;
}

.suggestionsContainer {
  display: block;
  position: absolute;
  width: 400px;
}

.suggestionsContainerOpen {
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.suggestionsList {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.suggestion {
  cursor: pointer;
  padding: 5px 10px;
}
