.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.previewSection {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;
  padding-left: 1.5rem;

  .previewArea {
    min-width: 320px;
    width: 320px;
    max-width: 320px;
  }

  .previewHelpArea {
    p {
      margin: 0
    }

    ;

    .toolBar {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      gap: 1rem;
      width: 44px;
    }
  }
}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  width: calc((100% - 16px) / 2);

  p {
    margin: 1rem 0;
  }

  .my0 {
    margin-bottom: 0;
    margin-top: 0;
  }

  .emptyViewChildren {
    padding: 1rem;

    .editBadge {
      width: 32px;
      height: 32px;
      position: absolute;
      z-index: 3;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      left: calc(50% - 16px);
      top: calc(50% - 16px);
      pointer-events: none;

      i {
        font-size: 32px;
        color: #155a55; // smappee ui-green
      }
    }
  }
}

.gallery {
  display: flex;
  flex-flow: wrap;
  gap: 20px;
  width: 100%;

  &.helpDeskContainer {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr 1fr 1fr;
    gap: 20px 16px; // row-gap, column-gap
  }
}

.galleryItem {
  /* ServiceDesk img dimensions: 168 x 126 */
  flex-grow: 1;
  max-width: 256px;
  width: 100%;
  height: 193px;
  background: #eee;
  margin: 0;
  position: relative;
  border: solid 1px rgba(255, 255, 255, .02);

  &.helpDeskGallery {
    max-width: 168px;
    width: 100%;
    height: 127px;
  }

  .caption {
    margin: 0.25rem 0;
    text-align: center;
    font-size: 0.875rem;

    &.helpDeskCaption {
      margin: 0;
      font-size: 0.75rem;
    }
  }

  .statusContainer {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 34px;
    background-color: transparent;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 3px;
    gap: 3px;
  }

  .statusIcon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background: white;

    i {
      font-size: 16px;
      color: var(--brand-color-atlantis); // Smappee logo green

      &.statusBusy {
        color: orange;
      }

      &.Smappee {
        color: var(--brand-color-atlantis);
      }
    }

    .SmappeeIcon {
      height: 16px;
      width: auto;
    }
  }

  .notAllowed {
    position: absolute;
    z-index: 2;
    top: 3px;
    right: 3px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background: white;

    i {
      font-size: 16px;
      color: red;
    }
  }
}

.overlay {
  opacity: 0.4;
}

.galleryItem::before {
  content: ' ';
  padding-top: 193px; // must be same as the image-height
  display: block;
}

.galleryItemImage {
  display: block;
  position: absolute;
  top: 0;
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: center;
}

.galleryItem {
  &.helpDeskGallery {
    &::before {
      content: ' ';
      padding-top: 127px;
      display: block;
    }
  }
}

.figureSelected {
  border: solid 1px red !important;
}

.rightCol {
  justify-content: space-between;
}