.main {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.mapContainer {
  display: flex;
  flex-grow: 1;
}

.legenda {
  display: flex;
  flex-direction: row;
  margin-top: 1.5rem;
}

.legendaItem {
  margin-right: 20px;
}

.connectorType {
  border: 1px solid white;
  border-radius: 4px;

  img {
    width: 50px;
    height: 44px;
  }
}
