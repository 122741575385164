@import '../../components/variables.scss';

.header,
.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header {
  margin-bottom: 5px;
}

.chooser {
  flex-direction: column;
  border-radius: 0.25rem;
  border: 1px solid var(--bs-border);
  max-height: 290px;
  overflow-y: auto;
}

.arrows {
  flex: 1;
  padding: 14px;
  text-align: right;
  display: inline-block;
  cursor: move;

  &:focus {
    outline: none;
  }
}

.column {
  display: flex;
  padding-left: 12px;
  align-items: center;

  .wrapper {
    width: 100%;
    display: flex;
    align-items: center;
  }

  &:hover {
    background-color: rgba(var(--brand-color-pumice), 0.15);
  }

  &.hidden {
    display: none;
  }
}
