@import '../../../components/variables.scss';

.applyPeriod {
  display: flex;
  flex-flow: row;
  padding-top: 5px;
  padding-bottom: 5px;

  button:first-child {
    margin-right: 15px;
  }
}

.tooltip {
  :global(.tooltip-inner) {
    max-width: fit-content;
    white-space: nowrap;
    padding: 5px;
  }
}

.warningIcon {
  margin-left: 15px;
  font-size: 20px;
}