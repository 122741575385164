@import './variables.scss';

.errorMessage {
  color: var(--brand-color-persian-red);
  flex-shrink: 1;

  :global {
    span.fal {
      margin-right: 10px;
    }
  }
}

.exclamation {
  color: var(--brand-color-persian-red);
  margin-right: 0.5rem;
}