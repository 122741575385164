.dayButton {
  // margin-left: 5px;
  // color: #155a55;
  // background-color: white;
  // border-color: #155a55;

  // &.active {
  //   color: white !important;
  //   background-color: #155a55 !important;
  //   border-color: #155a55 !important;
  // }

  // Rebranding
  background-color: var(--bs-white);
  border: solid 1px var(--bs-neutral-200);
  color: var(--bs-neutral-700);
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 2.75rem;
  height: 2.5rem;
  margin: 0 0.375rem 0.75rem;
  &:hover {
    background-color: var(--bs-neutral-100);
    color: var(--bs-neutral-700);
    border: solid 1px var(--bs-neutral-300);
  }
  &.active {
    background-color: var(--bs-neutral-300) !important;
    color: var(--bs-neutral-700) !important;
    border: solid 1px var(--bs-neutral-300) !important;
  }

}
