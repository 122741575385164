.fileBox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 320px;
  height: 240px;
  background: rgba(21, 90, 85, 0.1);
  border: 1px dashed rgba(21, 90, 85, 0.15);
  overflow: hidden;
  position: relative;

  .fileInput {
    position: absolute;
    opacity: 0;

    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    &.allowed {
      cursor: pointer;
    }

    &.notAllowed {
      cursor: not-allowed;
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;

    .previewContainer {
      display: block;
      width: 100%;
      height: 100%;
      position: relative;

      img {
        width: 100%;
      }
      .actionArea {
        position: absolute;
        width: 100%;
        height: 32px;
        bottom: 1rem;
        z-index: 20;
        display: flex;
        justify-content: center;
        pointer-events: none;

        .uploadBtn {
          color: #155a55;
          background-color: white;
          border-color: #155a55;
          color: #155a55;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          .icon {
            margin-right: 0.5rem;
          }
        }
      }
    }
  }
}
