@import '../../components/variables.scss';

.configStatus {
  display: flex;
  position: relative;

  :global {
    .form-group {
      margin-right: 30px;
    }
  }
}

table {
  tr.invalidRow {
    background-color: rgba(var(--brand-color-persian-red), 0.2);

    &:hover {
      background-color: rgba(var(--brand-color-persian-red), 0.3);
    }
  }

  tr.rowSpacing {
    td {
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }
}

td.optionsColumn {
  > div {
    margin-bottom: 0;
  }
}

td.groupColumn {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    display: inline-block;
  }
}

table.subRows {
  background-color: var(--brand-color-alabaster) !important;
  border: solid 2px var(--brand-color-pumice);

  tr {
    th,
    td {
      padding-top: 2px;
      padding-bottom: 2px;
    }

    th:first-of-type,
    td:first-of-type {
      padding-left: 10px;
    }
  }

  thead.subRowHeader {
    th {
      border-bottom: none !important;
    }
  }
}

.sensorReversed {
  text-align: center;

  th,
  td {
    padding-left: 0;
  }
}

.addButtons {
  position: absolute;
  top: 5px;
  right: 5px;
}

.maxCapacity {
  display: flex;
  flex-direction: row;
  align-items: center;

  > * {
    margin-right: 0.5rem;

    &:not(:first-child) {
      margin-left: 0.5rem;
    }
  }
}

.capacityQuickView {
  display: flex;
  flex-direction: column;

  > * {
    margin-top: 0.05rem;

    &:not(:first-child) {
      margin-bottom: 0.05rem;
    }
  }
}

.virtualGridChildsContainer {
  margin-left: 3rem;
}
.virtualChildBtn {
  background: white;
  border: 1px solid #ced4da;
  // margin-bottom: 0.5rem;
  margin-right: 1rem;
  width: 100%;
  max-width: 14.5rem;
}
.parentWrapper {
  width: 15.5rem;
  max-width: 15.5rem;
}
.virtualGridParent {
  max-width: 14.5rem;
  background: white;
  border: 1px solid #ced4da;
  margin-right: 1rem;
  width: 100%;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
}