@import '../variables.scss';

.locationMenu {
  span.icon {
    margin-right: 15px !important;
    font-size: var(--brand-label-size-lg);
    line-height: var(--brand-label-size-lg);
  }

  h6 {
    font-size: var(--brand-label-size-md);
  }
}

.myLocations {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.locationsWrapper {
  overflow-y: auto;
  max-height: calc(100vh - 200px);
  margin-top: -8px;
  margin-bottom: -8px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.inactiveItem {
  &:active {
    background-color: transparent !important;
  }
}

.location {
  .locationSerial {
    background-color: transparent;
  }

  .locationName {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    flex: 1 1;
  }

  .locationSerialInfo {
    color: #888;
  }

  .locationInfo {
    position: absolute;
    overflow: hidden;
    z-index: -1;
    left: 0;
    right: 0;
    top: 10px;
    display: flex;
  }
}

.locationItem, .locationItemInactive {
  display: flex;
  padding-top: 8px;
  padding-bottom: 8px;

  .locationSerial,
  .locationSerialMissing {
    min-width: 120px;
    display: inline-block;
  }

  .locationName,
  .locationNameMissing {
    overflow: hidden;
    max-width: 200px;
    margin-left: 15px;
    display: inline-block;
    text-overflow: ellipsis;
  }

  .locationSerialMissing,
  .locationNameMissing {
    color: var(--brand-color-pumice);
    font-style: italic;
  }
}

.locationItemInactive {
  color: #888;
}

.noResults {
  color: var(--brand-color-persian-red);
  padding: 0.5rem 1.5rem;
  text-align: center;
  margin-bottom: 0;
}
