.flex {
  display: flex;
  &.flexCol {
    flex-direction: column;
  }
  &.flexRow {
    flex-direction: row;
  }
  &.itemsCenter {
    align-items: center;
  }
  &.itemsStart {
    align-items: flex-start;
  }
  &.justifyBetween {
    justify-content: space-between;
  }
}
.cropButton {
  color: white;
  background-color: #155a55;
  font-size: 1.25rem;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  margin-top: 0;
  border: 0 none;
}
.canvasPreview {
  margin-top: 1rem;
}