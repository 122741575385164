.pricingGroupItem {
  padding: 15px 25px;
  background: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  margin-bottom: 8px;
}

.pricingGroupCore {
  display: flex;
  flex-direction: column;
  gap: 0px;
  text-align: left;
  flex-grow: 1;
}

.pricingGroupName {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  text-align: left;
}

.pricingGroupIcon {
  background-color: #fff;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 8px;
  border-radius: 6px;
  width: 32px;
  height: 32px;
  border: 1px solid #e2e8f0;
  flex: none;
  order: 1;
  cursor: pointer;
  flex-grow: 0;
}

.pricingGroupIcon:hover {
  transition: 0.3s;
  transform: translateX(2px);
}

.pricingGroupMembers {
  font-size: 14px;
}

.pricingGroupEmpty {
  color: gray;
  font-size: 14px;
}