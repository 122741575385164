@import '../variables.scss';

.dash-modal {
  .stretch {
    border-radius: 0;
    border: none;
  }

  &.sm,
  &.lg {
    /* Nothing special defined yet */
  }

  &.xl {
    @media (--screen-xl-above) {
      max-width: calc(var(--screen-xl-min) - 3 * 30px); /* Remove various padding */
    }
  }
}
.dash-content, .modal-content {
  border-radius: 0 !important;
  // max-width: 600px !important;
  min-width: 30rem !important;
  padding: 2rem !important;

  .modal-header {
    padding: 0 !important;
    border: unset !important;
    margin-bottom: 1.5rem !important;
    .close {
      line-height: 1.375rem !important;
      min-width: 3.125rem !important;
      max-width: 3.125rem !important;
      max-height: 2.5rem !important;
      padding: 0.5rem 0.875rem !important;
      border: solid 1px var(--bs-neutral-200) !important;
      border-radius: 9999px !important;
      margin: 0 !important;
    }
  }
  .modal-footer {
    padding: 1rem 0 0 !important;
    border: unset !important;
  }
}

.loading {
  z-index: 1060;
  position: absolute;
  width: calc(100% - calc(2 * var(--brand-card-border-width)));
  height: calc(100% - calc(2 * var(--brand-card-border-width)));
  top: var(--brand-card-border-width);
  left: var(--brand-card-border-width);
  background-color: rgba(#fff, 0.80);

  /* Center horizontally and vertically */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  :global(.fal) {
    font-size: 36px;
    padding: 15px;
  }
}

:global {
  .modal-dialog.modal-xl {
    max-width: 1300px;
  }
}
