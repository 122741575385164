.modalBody {
  margin-top: 1rem;
}
.helpTable {
  td {
    &:first-of-type {
      padding: 0.25rem !important;
    }
    &.headerCell {
      padding: 0.25rem;
      margin: 0;
      min-height: 2rem;
      height: 2rem;
    }
    &.description {
      padding: 0.25rem;
      margin: 0;
      min-height: 3.75rem;
      height: 3.75rem;
      text-align: left;
    }
    &.statusIcon {
      padding: 0.25rem;
      margin: 0;
      min-height: 3.75rem;
      height: 3.75rem;
      text-align: center
    }
    i {
      &.locked {
        color: red;
      }
      &.busy {
        color: orange;
      }
      &.default {
        color: #155a55;
      }
      &.confirmed, &.uploaded {
        color: green;
      }
    }
  }
}
.SmappeeIcon {
  height: 16px;
  width: auto;
}