@import '../variables.scss';

.dash-modal-header-actionable {
  align-items: center;
  border: none;

  h4 {
    margin-bottom: 0;
  }

  button[aria-label] {
    color: var(--brand-color-atlantis);
    font-weight: normal;
    text-shadow: none;
    font-size: 2rem;
    opacity: 1;

    &:focus,
    &:hover {
      color: var(--brand-color-sushi);
      font-weight: bold;
      cursor: pointer;
      outline: none;
      opacity: 1;
    }
  }
}
