@import './variables.scss';

.crashedcard {
  height: 100%;
  background-color: var(--brand-color-white);
//  border: var(--brand-card-border-width) solid var(--brand-color-persian-red);
  flex-flow: column;
  display: flex;
  flex: 1;
  height: 505px;
}

.body {
  flex: 1;
  padding-bottom: 15px;
  display: flex;
  flex-flow: column;
  overflow: hidden;
  position: relative;
}

.broken {
  height: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  color: var(--brand-color-persian-red);

  :global(.fal) {
    font-size: 36px;
    padding: 15px;
  }

  p {
    max-width: 50%;
  }
}
