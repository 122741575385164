@import '../../components/variables.scss';

table.legenda {
  border: 1px solid black;
  position: absolute;
  bottom: 0px;
  right: 0px;
  background-color: #ffffffb3;
}

.legenda {
  th {
    border-bottom: 1px solid black;
    white-space: nowrap;
  }

  tr {
    border-bottom: 1px solid black;
  }

  tr:last-child {
    border-bottom: none;
  }

  td, th {
    border-right: 1px solid black;
    padding: 2px 4px;
  }
}
