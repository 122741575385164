@import '../variables.scss';

.popover {
  border: 2px solid var(--brand-color-pumice);

  :global {
    div.popover-inner {
      background-color: rgba(var(--brand-color-sushi), 0.15);
    }

    span.arrow {
      &::before {
        border-bottom-color: var(--brand-color-pumice);
      }

      &::after {
        /* RGBA to RGB conversion of sunglow with a white background */
        border-bottom-color: #fff6e0;
      }
    }
  }
}
