
.filterPanelWrapper {
  display: flex;
  position: absolute;
  left: 0;
  right: 10px;
  top: 2rem;
  z-index: 20;
  align-items: flex-start;
  flex-direction: column;
}

.filterPanel {
  max-width: 500px;
  width: 80%;
  min-width: 400px;
  display: block;
  overflow: hidden;
  form > div {
    margin-bottom: 1rem !important;
  }
}
