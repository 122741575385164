@import '../variables.scss';

.button {
  border-radius: 1.5rem;
  .btn {
    box-shadow: none !important;

    &:hover,
    &:not([disabled]):not(.disabled):active {
      z-index: 10;
    }
  }
}