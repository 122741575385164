@import '../../components/variables.scss';

.stretch {
  flex: 1;
  display: flex;
  overflow-y: auto;
  flex-flow: column;
}

.chart {

}

.singlePlug {
  color: var(--brand-color-gunsmoke);
  margin-right: 15px;
  margin-bottom: 0;
  line-height: 1.2;
  font-size: 20px;
}
