.content {
  padding: 1rem;

  ol {
    list-style-type: decimal !important;
    padding-inline-start: 1.75em;
  }

  h2 {
    font-size: 2em;
    text-transform: uppercase;
    margin: 1rem 0;
    color: #155a55;
    text-align: center;
    font-weight: bold;
  }
}
