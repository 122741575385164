@import '../../components/variables.scss';

.tableCellDevice {
  width: 18.75rem /* 300px */;
  line-height: 1.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  img {
    display: inline-flex;
    width: 1.5rem /* 24px */;
    margin-right: 0.5rem /* 8px */;
  }
}
.tableCellDeviceChild {
  width: 18.75rem /* 300px */;
  line-height: 1.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  img {
    display: inline-flex;
    width: 1.5rem /* 24px */;
    margin-right: 0.5rem /* 8px */;
    margin-left: 1.5rem /* 24px */;
  }
}
.tableCellData {
  line-height: 1.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}