.button {
  display: flex;
  background: #6b7280;
  color: #ffffff;
  border-radius: 25px;
  padding: 8px 10px;
  font-weight: 700;
  font-size: 1rem;
  line-height: 12px;
  margin-right: 8px;
  align-items: center;
  border: none;
  cursor: pointer;

  &:hover {
    background: #111827;
  }
}