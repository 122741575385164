@import './variables.scss';

.chart {
  display: flex;
  height: 100%;

  div[data-highcharts-chart] {
    flex: 1;
  }
}

/* Fixes rendering issue #7528 https://github.com/highcharts/highcharts/issues/7528 */
:global {
  .highcharts-series path {
    stroke-linecap: initial;
  }
}
