@import './variables.scss';

.action {
  display: flex;
  align-items: center;
}

.periodSelector {
  :global(.popover) {
    min-width: 400px;
  }
}

.periodSelectorBody {
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(2, 1fr);
  padding-bottom: 12px;

  :global(.form-group) {
    display: flex;
    flex-flow: column;
    margin-bottom: 0;
  }
}

.applyPeriod {
  grid-column: 2;
  display: flex;
  flex-flow: column;
  align-items: flex-end;
  justify-content: flex-end;
  padding-top: 5px;
}

.tooltip {

  :global(.tooltip-inner) {
    max-width: fit-content;
    white-space: nowrap;
    padding: 5px;
  }
}

.warningIcon {
  margin-left: 15px;
  font-size: 20px;
}

.presentationMode {
  margin-left: 15px;
  color: var(--brand-color-corduroy);
  font-size: var(--brand-label-size-sm);
  line-height: var(--brand-label-size-sm);
}