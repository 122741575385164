@import '../components/variables.scss';

/* Customer primary colors */
$lightBlue: #41AADB;
$darkBlue:#245196;

$menuForegroundColor: white;
$menuBackgroundColor: $lightBlue;
//$buttonForegroundColor: $darkBlue;
//$buttonBackgroundColor: white;
$buttonForegroundColor: white;
$buttonBackgroundColor: $lightBlue;
$headerButtonForegroundColor: $darkBlue;

/* Derived / standard colors usually not customized by customer */
$menuBackgroundColor: $menuBackgroundColor;
$menuTitleColor: $darkBlue;
$menuTextColor: $menuForegroundColor;
$menuHoverBackgroundColor: var(--brand-color-corduroy);

$menuActiveTextColor: white;
$menuActiveBackgroundColor: darken($menuBackgroundColor, 30%);
$menuActiveHoverBackgroundColor: var(--brand-color-corduroy);

$menuActionsTextColor: white;
$menuActionsBackgroundColor: lighten($menuBackgroundColor, 10%);

$buttonForegroundDarker: darken($buttonForegroundColor, 10%);


/* Actual classes */
@import './Styles--common.module.scss';
