.error {
  height: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  :global(.fal) {
    font-size: 36px;
    padding: 15px;
  }

  p {
    max-width: 50%;
    button {
      margin-right: 0.5rem;
    }
  }
}
