@import '../variables.scss';

.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;

  &.fillHeight {
    height: 100%;
  }
}
.rowWrapper {
  padding: 0;
  margin: 0;
  &:first-of-type {
    margin-top: 0.0625rem;
  }

}

.infiniteScroll {
  overflow-y: auto;
}

.tableContainer {
  flex: 1;
  overflow-y: auto;
  border-bottom: 1px solid var(--bs-border);
}

.clickableRow {
  cursor: pointer;

  &:hover {
    background-color: var(--bs-natural-white) !important;
  }
}

.row {
  &:hover {
    background-color: var(--bs-natural-white) !important;
  }
}

.columnHeaderWrapper {
  z-index: 10; /* prevents issues with checkboxes appearing on top of the header */
}
/*.columnHeaderWrapper {
  display: inline-block;

  .columnHeader {
    max-width: 180px;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
}*/
.columnHeader {
  text-overflow: ellipsis;
  display: flex;
  flex-direction: row;
  width: 100%;
  font-weight: 700;
  line-height: 1.36;
  color: var(--bs-neutral-700);

  .resizeHandle {
    visibility: hidden;
  }

  &:hover .resizeHandle {
    visibility: visible;
  }
}

.resizeHandle {
  padding-left: 5px;
  cursor: col-resize;
  -webkit-user-select: none;
  -moz-user-select: none;
}

.sortColumn {
  display: flex;
  align-items: center;
  border-radius: 0 !important; /* no border-radius on sort buttons */

  :global {
    span.fal {
      margin-left: 5px;
    }
  }
}

:global {
  table.table {
    flex: 1;
    margin-bottom: 0;
    table-layout: fixed;
    width: auto;
    min-width: 100%;

    tr:hover {
      background-color: var(--bs-natural-white);
    }

    th {
      position: sticky;
      top: 0;
      background-color: white;
      border-bottom: none;
    }

    th:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      border-bottom: 1px solid var(--bs-border);
    }

    th,
    td {
      line-height: 1.375rem !important;
      vertical-align: middle;
      border-top: none;

      overflow: hidden;
      text-overflow: ellipsis;

      /* Explicitly not combined together */
      padding-left: 0.50rem;
      padding-right: 0.50rem;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;

      &:first-of-type {
        padding-left: 0.0625rem;
      }

      &:last-of-type {
        padding-right: 0.25rem;
      }
    }
    
    &,
    thead {
      th {
        font-weight: normal;
        color: var(--brand-color-gunsmoke);
        white-space: nowrap;

        /*&[title] {
          text-decoration-style: dashed;
          text-decoration-line: underline;
          text-decoration-color: var(--brand-color-atlantis);
        }*/

        .sortColumn:hover {
          span {
            text-decoration: underline;
          }
        }
      }
    }

    tbody {
      overflow-y: auto;
    }
  }
}

.right {
  text-align: right;
  justify-content: right;

  .sortColumn {
    float: right
  }
}

.center {
  text-align: center;
  justify-content: center;
}

.left {
  text-align: left;
  justify-content: left;
}

.pagination {
  margin-top: 10px;
}

.flexTestWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;

  .flexTestTable {
    background-color: #dddddd;
    overflow-y: auto;
    flex: 1;
  }

  .flexTestPaging {
    background-color: #cccccc;
  }
}

.noDataRow {
  .noDataText {
    font-style: italic;
  }

  &:hover {
    background-color: transparent !important;
  }
}

th.right {
  padding-right: 0 !important;
}

td.right {
  padding-right: 11px !important;
}

th[data-name="actions"] {
  position: relative;
  text-align: center;
}

th[data-name="actions"] > span {
  display: inline-block;
  width: 100%;
  text-align: center;
}