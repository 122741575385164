:root {
  --shadow-color: #222;
}
.content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  background: white;
  padding: 0.5rem;

  h2 {
    font-size: 1rem;
    text-transform: uppercase;
    margin: 1rem 0;
    color: #155a55;
  }
  h6 {
    font-size: 0.8rem;
    text-transform: uppercase;
    margin: 0.5rem 0;
    color: #155a55;
  }

  .cardProposal {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1rem;
    background: white;
    border-radius: 0.125rem;
    box-shadow: rgba(21, 90, 85, 0.1) 0px 1px 3px 0px, rgba(21, 90, 85, 0.06) 0px 1px 2px 0px;
    width: 100%;
    .row {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
    }
    .col {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: calc(100% - 1rem);
      &.list {
        align-items: flex-start;
        .dropdown {
          max-width: calc(100% - 1.5rem);
          width: 100%;
        }
      }
      &.action {
        align-items: flex-end;
      }
      &.alignRight {
        align-items: flex-end;
      }
      .colRight {
        max-width: calc(100% - 1.5rem) !important;
        width: 100% !important;
      }
    }
  }
}
