@font-face {
	font-family: "icons";
  src: url('./icons/icons.ttf') format('truetype');
}

.iconInternet {
  &:before {
    display: inline-block;
    content: "\EA01";
    font-family: "icons";
    font-size: 2rem;
  };
}

.iconLocation {
  &:before {
    display: inline-block;
    content: "\EA02";
    font-family: "icons";
  };
}

.iconMail {
  &:before {
    display: inline-block;
    content: "\EA03";
    font-family: "icons";
    font-size: 2rem;
  };
}

.iconSupport {
  &:before {
    display: inline-block;
    content: "\EA04";
    font-family: "icons";
  };
}

.iconTelephone {
  &:before {
    display: inline-block;
    content: "\EA05";
    font-family: "icons";
    font-size: 2rem;
  };
}
