@import '../variables.scss';

.alert {
  /* Add padding as not to overlap with close button */
  padding-left: 0.75rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-right: 60px;
  font-size: 1rem;

  button[aria-label] {
    color: var(--brand-color-atlantis);
    font-weight: normal;
    text-shadow: none;
    font-size: 2rem;
    opacity: 1;

    &:focus,
    &:hover {
      color: var(--brand-color-sushi);
      font-weight: bold;
      cursor: pointer;
      outline: none;
      opacity: 1;
    }
  }

  p {
    margin: 0;
    padding: 0;
  }

  p+p {
    margin-top: 0.5rem;
  }

  &.warning {
    color: var(--brand-color-persian-red);
    background-color: var(--brand-color-white);
    border: 2px solid var(--brand-color-persian-red);
  }

  &.fixed {
    transform: translateX(-50%);
    position: fixed;
    z-index: 9999;
    top: 60px;
    left: 50%;
  }
}