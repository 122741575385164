body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.notification-container {
  top: auto !important;
  bottom: 0;
  padding-bottom: 0px !important;
  margin-bottom: 15px !important;
}

.nav-item {
  cursor: pointer;
}

.layout-wrapper {
  height: 100vh;
  overflow-y: auto;
}

/* temporary radix-popper hack */
div[data-radix-popper-content-wrapper] {
  background: white !important;
}

/* ChargingSessions actionrow -- Safari, screenWidth <= 1512 */
.t-card--ChargingSessions .dropdown div > input.form-control {
  max-width: 11rem !important;
}