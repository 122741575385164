.formGroup {
  padding: 0;
  margin-bottom: 1rem;
  select {
    width: 100% !important;
  }
}

.compact {
  margin-bottom: 0px;

  label {
    margin: 0px;
  }
}

.wrap {
  label {
    white-space: normal;
    display: flex;
  }
}

.noSpinner {
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
}

.label {
  display: flex;

  > * + * {
    margin-left: 1rem;
  }
}
.mt-0 {
  margin-top: 0 !important;
}
.mt-1 {
  margin-top: 0.25rem !important;
}
.mt-2 {
  margin-top: 0.5rem !important;
}
.mt-3 {
  margin-top: 0.75rem !important;
}
.mt-4 {
  margin-top: 1rem !important;
}
.ml-0 {
  margin-left: 0 !important;
}
.mr-0 {
  margin-right: 0 !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.pl-0 {
  padding-left: 0 !important;
}
.pl-4 {
  padding-left: 1rem !important;
}
.pr-0 {
  padding-right: 0 !important;
}