:root {
  /* Typography */
  --brand-font-family: 'Inter', sans-serif;
  --brand-font-heading: 'Soleil', sans-serif;
  --brand-icon-font-family: 'Font Awesome 5 Pro', var(--brand-font-family);
  --brand-smappee-font-family: 'SmappeeIcons', var(--brand-font-family);

  --bs-paragraph-01: 0.9375rem; /* 15 pt */
  --bs-paragraph-02: 0.8125rem; /* 13 pt */
  --bs-font-size-heading-01: 2.25rem /* 36 pt */;
  --bs-font-size-heading-02: 1.6875rem /* 27 pt */;
  --bs-font-size-heading-03: 1,.375rem /* 23 pt */;
  --bs-font-size-heading-04: 1.1875rem; /* 19pt */
  --bs-font-weight-bold: 700;


  --white: 0, 0%, 100%;
  --natural-white: 27, 100%, 98%;
  --bs-natural-white: #FFFAF6;
  --black: 0, 0%, 0%;
  --bs-white:  #FFFFFF;
  --bs-black: #000000;


  /* Opacitiy values */
  --transparant: 0, 0%, 100%, 0;
  --bs-transparant: rgba(255, 255, 255, 0);
  --light-80: 0, 0%, 100%, 0.8;
  --bs-light-80: rgba(255, 255, 255, 0.8);
  --light-20: 0, 0%, 100%, 0.2;
  --bs-light-20: rgba(255, 255, 255, 0.2);
  --dark-20: 240, 2%, 8%, 0.2;
  --bs-dark-20: rgba(20, 20, 21, 0.2);
  --dark-80: 240, 2%, 8%, 0.8;
  --bs-dark-80: rgba(20, 20, 21, 0.8);

  /* Neutral, HSL */
  --neutral-050: 27, 70%, 97%;
  --neutral-100: 20, 38%, 94%;
  --neutral-200: 19, 30%, 89%;
  --neutral-300: 21, 20%, 80%;
  --neutral-400: 16, 5%, 60%;
  --neutral-500: 0, 0%, 44%;
  --neutral-600: 0, 0%, 35%;
  --neutral-700: 240, 1%, 20%;
  --neutral-800: 240, 2%, 12%;
  --neutral-900: 240, 2%, 8%;

  /* Neutral, HEX */
  --bs-neutral-050: #fdf7f2;
  --bs-neutral-100: #f5ede9;
  --bs-neutral-200: #ece1dc;
  --bs-neutral-300: #d6c9c2;
  --bs-neutral-400: #9e9693;
  --bs-neutral-500: #717171;
  --bs-neutral-600: #595959;
  --bs-neutral-700: #333334;
  --bs-neutral-800: #1e1e1f;
  --bs-neutral-900: #141415;

  /* Blue, HSL */
  --blue-100: 247, 33%, 95%;
  --blue-200: 223, 41%, 86%;
  --blue-300: 211, 39%, 78%;
  --blue-500: 219, 41%, 59%;
  --blue-700: 220, 41%, 39%;
  --blue-800: 223, 41%, 26%;

  /* Blue, HEX */
  --bs-blue-100: #eeedf6;
  --bs-blue-200: #cdd5ea;
  --bs-blue-300: #b0c5dc;
  --bs-blue-500: #6c8ac1;
  --bs-blue-700: #3b568c;
  --bs-blue-800: #27365d;

  /* Green, HSL */
  --green-100: 173, 40%, 92%;
  --green-200: 164, 27%, 84%;
  --green-300: 154, 24%, 80%;
  --green-500: 164, 27%, 59%;
  --green-700: 163, 27%, 35%;
  --green-800: 165, 28%, 17%;
  --toggle-green-secondary: 164, 27%, 59%;

  /* Green, HEX */
  --bs-green-100: #e3f3f1;
  --bs-green-200: #cce2dc;
  --bs-green-300: #bfd8cd;
  --bs-green-500: #7ab3a4;
  --bs-green-700: #417264;
  --bs-green-800: #1f3731;
  --bs-toggle-green-secondary: #7ab3a4;

  /* Yellow, HSL */
  --yellow-100: 42, 100%, 91%;
  --yellow-200: 40, 100%, 85%;
  --yellow-300: 44, 100%, 75%;
  --yellow-500: 40, 89%, 63%;
  --yellow-700: 36, 78%, 45%;
  --yellow-800: 36, 82%, 25%;

  /* Yellow, HEX */
  --bs-yellow-100: #fff1d0;
  --bs-yellow-200: #ffe5b2;
  --bs-yellow-300: #ffdd80;
  --bs-yellow-500: #f5bc4d;
  --bs-yellow-700: #ca8319;
  --bs-yellow-800: #764b0c;

  /* Red , HSL */
  --red-100: 343, 69%, 95%;
  --red-200: 343, 72%, 92%;
  --red-300: 349, 91%, 82%;
  --red-500: 351, 100%, 68%;
  --red-700: 351, 57%, 49%;
  --red-800: 351, 52%, 39%;

  /* Red, HEX */
  --bs-red-100: #fbe9ee;
  --bs-red-200: #f9dae3;
  --bs-red-300: #fba8b7;
  --bs-red-500: #ff5c75;
  --bs-red-700: #c5364c;
  --bs-red-800: #97303f;

  /* Smappee */
  --smappee-500: 76, 59%, 47%;
  --bs-smappee-500: #99c031;
  --smappee-100: 85, 77%, 90%;         /* experimental */
  --bs-smappee-100: #e9f9d2;
  --smappee-green: var(--smappee-500);
  --bs-smappee-green: #99c031;

  --background: var(--white); /* white, #FFFFFF, surface-primary */
  --foreground: var(--neutral-700); /* #333334, dark-grey, text-primary */

  --card: var(--white); /* #FFFFFF, surface-primary */
  --card-foreground: var(--neutral-700); /* #333334, dark-grey, text-primary */

  --popover: var(--white); /* #FFFFFF, surface-primary */
  --popover-foreground: var(--neutral-700); /* #333334, dark-grey, text-primary */

  --placeholder: var(--transparent); /* transparent */
  --placeholder-foreground: var(--neutral-300); /* neutral-300, #D6C9C2 */

  --primary: var(--neutral-700); /* #333334, dark-grey, primary */
  --primary-foreground: var(--neutral-100); /* #f5ede9 */

  --secondary: var(--smappee-500); /* #99c031, smappee-green */
  --secondary-foreground: var(--white); /* white, #FFFFFF */

  --muted: var(--neutral-300); /* neutral-300, #D6C9C2 */
  --muted-foreground: var(--neutral-050); /* #fdf7f2 */

  --accent: var(--neutral-050); /* #fdf7f2, surface-primary-support */
  --accent-foreground: var(--black); /* black, #000000 */

  --destructive: var(--red-500); /* #ff5c75, surface-negative */
  --destructive-foreground: var(--white); /* #FFFFFF, white */
  --destructive-hover: var(--red-700); /* #c5364c */

  --switch: var(--smappee-500); /* smappee green */
  --switch-foreground: var(--white); /* white */
  --switch-unselected: var(--muted); /* neutral-300, #D6C9C2 */

  --switch-disabled: var(--primary-light-grey);
  --switch-disabled-foreground: var(--primary-light-grey);

  --switch-negative: var(--red-500);
  --switch-negative-foreground: var(--white);
  --switch-negative-muted: var(--red-300);

  --border: var(--neutral-200); /* #ece1dc, border-primary */
  --bs-border: var(--bs-neutral-200);
  --input: var(--neutral-050); /* #FDF7F2, natural white */
  --bs-input-text-error: var(--bs-red-700);
  --bs-input: var(--bs-neutral-050);
  --ring: var(--smappee-500); /* #99c031, smappee-green */
  --bs-ring: var(--bs-smappee-500);

  --radius: 0.5rem;

  /* Shadow colors */
  --shadow-dark: rgba(0, 0, 0, 0.40);    /* 0, 0%, 0%, 0.4 */
  --shadow-light: rgba(85, 37, 9, 0.05); /* 22, 81%, 18%, 0.05 */

  /* Brand */
  --primary-dark-grey: var(--neutral-700);
  --primary-smappee-green: var(--smappee-500);
  --primary-light-grey: var(--neutral-200);
  --primary-natural-white: var(--neutral-050);

  --secondary-natural-grey: var(--neutral-500);
  --bs-secondary-natural-grey: var(--bs-neutral-500);
  --secondary-water: var(--blue-200);
  --bs-secondary-water: var(--bs-blue-200);
  --secondary-sun: var(--yellow-200);
  --bs-secondary-sun: var(--bs-yellow-200);
  --secondary-gas: var(--green-200);
  --bs-secondary-gas: var(--bs-green-200);
  --secondary-energy: var(--red-500);
  --bs-secondary-energy: var(--bs-red-500);
  --secondary-earth: var(--red-200);
  --bs-secondary-earth: var(--bs-red-200);

  /* Surface */
  --surface-primary: var(--white);
  --surface-primary-support: var(--neutral-050);
  --surface-focus: var(--smappee-500);
  --surface-focus: var(--smappee-500);
  --surface-warning: var(--yellow-500);
  --surface-negative: var(--red-500);
  --surface-disabled: var(--neutral-200);

  /* Border */
  --border-primary: var(--neutral-200);
  --border-focus: var(--blue-500);
  --border-positive: var(--smappee-500);
  --border-warning: var(--yellow-500);
  --border-negative: var(--red-500);
  --border-disabled: var(--neutral-100);

  /* Text */
  --text-primary: var(--neutral-700);
  --bs-text-primary: var(--bs-neutral-700);
  --text-secondary: var(--neutral-600);
  --text-focus: var(--smappee-500);
  --text-positive: var(--smappee-500);
  --text-warning: var(--yellow-700);
  --text-negative: var(--red-700);
  --text-disabled: var(--neutral-300);

  /* Box shadows */
  --shadow-low: 0 4px 8px var(--shadow-light);
  --shadow-medium: 0 12px 24px var(--shadow-light);
  --shadow-high: 0 16px 32px var(--shadow-light);
  --shadow-inversed: 0 -16px 32px var(--shadow-light);

  /* Legacy colors & variables */
  /* Primary colors */
  --brand-color-atlantis: #99c031;
  // --brand-color-eden: #135a55;
  // --brand-color-eden: #1f3731; // --green-800
  --brand-color-eden: #7AB3A4; // --green-500
  --brand-color-clementine: #eb6d01;
  // --brand-color-pacific-blue: #009fc7;
  --brand-color-pacific-blue: #6C8AC1;

  /* Secondary colors */
  --brand-color-sushi: #99c031;
  // --brand-color-sushi: #7fa32f;
  // --brand-color-sunglow: #fec331;
  --brand-color-sunglow: #FFE5B2;
  // --brand-color-deep-sea-green: #074b46;
  --brand-color-deep-sea-green: #99c031;
  // --brand-color-alabaster: #f8f8f8;
  --brand-color-alabaster: #fdf7f2;
  // --brand-color-persian-red: #d13b3b;
  --brand-color-persian-red: #FF5C75;

  /* Tertiary colors */
  --brand-color-outer-space: #2c3935; // Unused
  --brand-color-corduroy: #5b6562; // Unused
  --brand-color-gunsmoke: #8a918f; // Unused
  --brand-color-pumice: #d0d3d2;
  --brand-color-black-haze: #e7e9e8; // Unused

  /* Font and background colors */
  --brand-color-white: #fff;
  --brand-color-aztec: #15241f;

  /* Media query breakpoints */
  --screen-xs-min: 0; /* Extra small screen / phone */
  --screen-sm-min: 576px; /* Small screen / tablet */
  --screen-md-min: 768px; /* Medium screen / desktop */
  --screen-lg-min: 992px; /* Large screen / wide desktop */
  --screen-xl-min: 1200px; /* Extra large screen / extra wide desktop */

  /* Custom media queries, variables do not seem to work here */
  @custom-media --screen-xs (max-width: 575px);
  @custom-media --screen-sm (min-width: 576px) and (max-width: 767px);
  @custom-media --screen-md (min-width: 768px) and (max-width: 991px);
  @custom-media --screen-lg (min-width: 992px) and (max-width: 1199px);
  @custom-media --screen-xl (min-width: 1200px);
  @custom-media --screen-md-below (max-width: 991px);
  @custom-media --screen-md-above (min-width: 768px);
  @custom-media --screen-lg-above (min-width: 992px);
  @custom-media --screen-xl-above (min-width: 1200px); /* Preserves formatting */

  /* Container max widths */
  --container-sm-max: 540px;
  --container-md-max: 720px;
  --container-lg-max: 960px;
  --container-xl-max: 1140px;

  /* Layout */
  --max-content-width: var(--container-xl-max);

  /* Component sizes */
  --brand-component-size-lg: 1.1875rem; // Old: 18 : 16px, New: 19 : 16
  --brand-component-size-md: 0.9375rem; // Old: 17 : 16px, New: 15 : 16
  --brand-component-size-sm: 0.8125rem; // Old: 15 : 16px, New: 13 : 16

  /* Label sizes */
  --brand-label-size-lg: calc(var(--brand-component-size-lg) - 2px);
  --brand-label-size-md: calc(var(--brand-component-size-md) - 2px);
  --brand-label-size-sm: calc(var(--brand-component-size-sm) - 2px);

  /* Checkbox sizes */
  --brand-checkbox-size-lg: calc(var(--brand-component-size-lg) + 2px);
  --brand-checkbox-size-md: calc(var(--brand-component-size-md) + 2px);
  --brand-checkbox-size-sm: calc(var(--brand-component-size-sm) + 2px);

  /* Component sizes */
  --brand-header-height: 80px;
  --brand-navigation-width: 240px;
  --brand-navigation-width-collapsed: 88px;
  --brand-card-border-width: 2px;
}

.dark {
  /* Dark Mode */
  /* Surface */
  --surface-primary: var(--neutral-800);
  --surface-primary-support: var(--neutral-900);
  --surface-focus: var(--smappee-500);
  --surface-focus: var(--smappee-500);
  --surface-warning: var(--yellow-500);
  --surface-negative: var(--red-500);
  --surface-disabled: var(--neutral-700);

  /* Border */
  --border-primary: var(--neutral-600);
  --border-focus: var(--blue-500);
  --border-positive: var(--smappee-500);
  --border-warning: var(--yellow-500);
  --border-negative: var(--red-500);
  --border-disabled: var(--neutral-700);

  /* Text */
  --text-primary: var(--neutral-100);
  --text-secondary: var(--neutral-300);
  --text-focus: var(--smappee-500);
  --text-positive: var(--smappee-500);
  --text-warning: var(--yellow-500);
  --text-negative: var(--red-500);
  --text-disabled: var(--neutral-600);
  --text-inactive: var(--neutral-600);

  /* Box shadows */
  --shadow-low: 0 4px 8px var(--shadow-dark);
  --shadow-medium: 0 12px 24px var(--shadow-dark);
  --shadow-high: 0 16px 32px var(--shadow-dark);
  --shadow-inversed: 0 -16px 32px var(--shadow-dark);
}

@supports (font-variation-settings: normal) {
  :root {
    font-family: 'Inter var', sans-serif;
    font-feature-settings: 'liga' 1, 'calt' 1; /* fix for Chrome */
  }
}