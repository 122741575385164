@import '../variables.scss';

.dash-input-group-text {
  border-color: var(--brand-color-pumice);
  border-width: 2px;
  font-size: var(--brand-component-size-md);
  background-color: var(--brand-color-alabaster);

  &.lg {
    font-size: var(--brand-component-size-lg);
  }

  &.sm {
    font-size: var(--brand-component-size-sm);
  }

  .input-group-append {
    :local {
      .inputGroupText {
        border-left: none;
      }
    }
  }
}
